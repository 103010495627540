import { FormattedMessage, useIntl } from 'react-intl'
import { Fragment, useEffect, useState } from 'react'
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'

import AddToCart from 'src/components/AddToCart/AddToCart'
import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import SlideOver from 'src/components/SlideOver/SlideOver'
import PrimaryButton from 'src/components/UI/PrimaryButton/PrimaryButton'
import useStore from 'src/stores/useStore'
import { CrossIcon, PlusIcon } from 'src/components/UI/Icons/Icons'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import { getCentraSrcSet } from 'src/utils/image'
import { getCentraProducts } from 'src/utils/category'
import { parseCentraProductToAinoProduct } from 'src/utils/parser'

import styles from './Favorites.module.scss'

import type { AinoProduct, Maybe } from 'src/apollo/types'

const InnerFavorites = observer((): JSX.Element => {
  const intl = useIntl()
  const { locale } = useRouter()
  const { cart, ui } = useStore()
  const [allAdded, setAllAdded] = useState(false)
  const [hasFavorites, setHasFavorites] = useState<any>(null)

  const closeNav = () => {
    setAllAdded(false)
    ui.setActiveMenu('')
  }

  const [products, setProducts] = useState([])

  const fetchProducts = async () => {
    const response = await getCentraProducts({
      token: cart.token,
      productIds: cart.favorites,
      locale: locale,
    })
    if (response && response.products) {
      setProducts(
        response.products.map((p: any) => parseCentraProductToAinoProduct(p))
      )
      setHasFavorites(
        response.products.filter((item: any) => {
          const stock =
            item && item.items && item.items[0] ? item.items[0].stock : '0'

          return (
            item &&
            stock &&
            parseInt(stock) > 0 &&
            item.items &&
            item.items[0] &&
            item.items[0].item
          )
        }).length === products.length
      )
    } else {
      setHasFavorites(false)
      setProducts([])
    }
  }

  useEffect(() => {
    if (cart.favorites && cart.favorites.length > 0) {
      fetchProducts()
    } else {
      setProducts([])
    }
  }, [cart.favorites])

  const timer = (ms: number) => new Promise((res) => setTimeout(res, ms))

  async function addAllToCart(products: AinoProduct[] | Maybe<AinoProduct>[]) {
    const favorites = [...products].filter((item: any) => {
      const stock =
        item && item.items && item.items[0] ? item.items[0].stock : '0'

      return (
        item &&
        stock &&
        parseInt(stock) > 0 &&
        item.items &&
        item.items[0] &&
        item.items[0].item
      )
    })
    for (let i = 0; i < favorites.length; i++) {
      const item = favorites[i]
      const stock =
        item && item.items && item.items[0] ? item.items[0].stock : '0'
      if (
        item &&
        stock &&
        parseInt(stock) > 0 &&
        item.items &&
        item.items[0] &&
        item.items[0].item
      ) {
        cart.addItem({ item: item.items[0].item })
        cart.toggleFavorite(item.id)
      }
      if (i + 1 >= favorites.length) {
        setAllAdded(true)
      } else {
        await timer(400) // then the created Promise can be awaited
      }
    }
  }
  return (
    <Fragment>
      <SlideOver
        active={ui.activeMenu === 'favorites'}
        onCloseClick={() => {
          ui.setActiveMenu('')
        }}
        className={
          ui.activeMenu === 'favorites'
            ? [styles.wrapper, styles.active].join(' ')
            : styles.wrapper
        }
      >
        <Flex
          className={
            ui.activeMenu === 'favorites'
              ? [styles.wrapper, styles.active].join(' ')
              : styles.wrapper
          }
          flexDirection="column"
          width={1}
          role="dialog"
        >
          <Fragment>
            <Flex
              paddingX={[5, null, null, 10]}
              paddingTop={[6, null, null, 10]}
              width={1}
              marginBottom={[5, null, null, 10]}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box as="h3" className={styles.title}>
                <FormattedMessage
                  defaultMessage="Your Favorites"
                  id="HS0x45"
                  description="Title for a 'Favorites' page"
                />
              </Box>
              <Box
                onClick={() => {
                  closeNav()
                }}
                className={styles.closeIcon}
                as="button"
                aria-label={intl.formatMessage({
                  defaultMessage: 'Close favorites',
                  id: '4WsiZ2',
                  description:
                    'Text for button that closes the favorites window',
                })}
              >
                <PlusIcon />
              </Box>
            </Flex>
            <Flex
              paddingX={[3, null, null, 8]}
              className={styles.productList}
              flexWrap="wrap"
              width={1}
            >
              <AnimateSharedLayout>
                <AnimatePresence>
                  {cart.favorites &&
                    cart.favorites.length > 0 &&
                    products &&
                    products.map((favItem: any) => {
                      // if (!favItem || !favItem.slug) {
                      //   return null
                      // }

                      const { media, name, slug, id, price, items } = favItem
                      const stock = items && items[0] ? items[0].stock : '0'
                      return (
                        <motion.div
                          layout
                          className={styles.column}
                          key={id}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          <Flex
                            width={1}
                            paddingX={[2, null, null, 2]}
                            flexDirection="column"
                            className={styles.product}
                            marginBottom={[6]}
                            key={id}
                            onClick={() => {
                              closeNav()
                            }}
                          >
                            <LinkComponent href={`/product/${slug}`}>
                              <Box
                                marginBottom={2}
                                className={styles.imageWrapper}
                                width={1}
                              >
                                {media && media[0] && (
                                  <Image
                                    srcSet={getCentraSrcSet(media[0])}
                                    alt={name ? name : ''}
                                  />
                                )}
                                <Flex
                                  onClick={(e: any) => {
                                    e.preventDefault()
                                    e.stopPropagation()

                                    cart.toggleFavorite(favItem.id)
                                  }}
                                  className={styles.remove}
                                  as="button"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <CrossIcon
                                    color="#fff"
                                    width={8}
                                    height={8}
                                  />
                                </Flex>

                                <Box className={styles.add}>
                                  {items && items[0] && (
                                    <AddToCart
                                      isFavorite={true}
                                      small
                                      onToggleFavorite={() => {
                                        cart.toggleFavorite(favItem.id)
                                      }}
                                      item={items[0]}
                                      product={favItem}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Flex
                                width={1}
                                style={{ position: 'relative' }}
                                flexDirection="column"
                              >
                                <Box className={styles.name} as="span">
                                  {name}
                                </Box>
                                {stock && parseInt(stock) > 0 ? (
                                  <Box className={styles.price} as="span">
                                    {price}
                                  </Box>
                                ) : (
                                  <Box className={styles.price} as="span">
                                    <FormattedMessage
                                      defaultMessage="Out of stock"
                                      id="RGPYN3"
                                      description="Text when product is out of stock"
                                    />
                                  </Box>
                                )}
                              </Flex>
                            </LinkComponent>
                          </Flex>
                        </motion.div>
                      )
                    })}
                </AnimatePresence>
              </AnimateSharedLayout>
              <AnimatePresence>
                {!(cart && cart.favorites && cart.favorites.length > 0) && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={styles.allAdded}
                  >
                    {allAdded ? (
                      <FormattedMessage
                        defaultMessage="All items have been added to your cart"
                        id="k5Kt3b"
                        description="Text shown on 'Favorites' page when all favorites have been added to cart"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="You have no favorites :("
                        id="BK0KuP"
                        description="Text shown on 'Favorites' page when customer has no favorites"
                      />
                    )}
                  </motion.span>
                )}
              </AnimatePresence>
            </Flex>
            <Box
              paddingY={[5, null, null, 5]}
              paddingX={[5, null, null, 10]}
              marginTop="auto"
              width={1}
              className={styles.button}
            >
              {allAdded ? (
                <LinkComponent href="/checkout">
                  <PrimaryButton
                    onClick={() => {
                      closeNav()
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Go to checkout"
                      id="rMGQsS"
                      description="Link to checkout page from 'Favorites' page"
                    />
                  </PrimaryButton>
                </LinkComponent>
              ) : cart.favorites && cart.favorites.length < 1 ? (
                <PrimaryButton
                  onClick={() => {
                    closeNav()
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Back to shop"
                    id="U67Hgt"
                    description="Link back to shop from 'Favorites' page"
                  />
                </PrimaryButton>
              ) : hasFavorites ? (
                <PrimaryButton
                  onClick={() => {
                    addAllToCart(products)
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Add {numberOfItems} {numberOfItems, plural, one {item} other {items} } to cart"
                    id="8RymuI"
                    description="Text for button that adds all favorites to shopping cart"
                    values={{ numberOfItems: cart.favorites.length }}
                  />
                </PrimaryButton>
              ) : null}
            </Box>
          </Fragment>
        </Flex>
      </SlideOver>
    </Fragment>
  )
})

const Favorites = (): JSX.Element | null => {
  const [showFavorites, setShowFavorites] = useState(false)

  useEffect(() => {
    setShowFavorites(true)
  }, [])

  if (!showFavorites) {
    return null
  }

  return <InnerFavorites />
}

export default Favorites
