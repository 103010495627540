import { default as NextLink } from 'next/link'
import { FC, Fragment, ReactNode } from 'react'

type AriaCurrent =
  | boolean
  | 'step'
  | 'location'
  | 'time'
  | 'true'
  | 'false'
  | 'date'
  | 'page'

export interface Props {
  ariaLabel?: string
  ariaCurrent?: AriaCurrent
  children: ReactNode
  className?: string
  href: string
  locale?: string
  onClick?: any
  role?: string
}

const LinkComponent: FC<Props> = ({
  ariaLabel,
  ariaCurrent,
  children,
  className,
  href,
  onClick,
  role,
}) => {
  if (!href) {
    return children ? <Fragment>{children}</Fragment> : null
  }

  const anchorProps: any = {
    ...(className && { className }),
    ...(onClick && { onClick }),
    ...(ariaLabel && { 'aria-label': ariaLabel }),
    ...(ariaCurrent && { 'aria-current': ariaCurrent }),
    ...(role && { role }),
    ...(href.startsWith('http') && {
      rel: 'noopener noreferrer',
      target: '_blank',
    }),
  }

  if (!/^[a-z]+\:/i.test(href)) {
    href = href.replace(/\/$/, '').replace(/^\/?/, '/')
  }

  return (
    <NextLink href={href}>
      <a {...anchorProps}>{children}</a>
    </NextLink>
  )
}

export default LinkComponent
