import Head from 'next/head'
import Script from 'next/script'
import { Fragment, ReactElement, ReactNode, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { ThemeProvider } from '@emotion/react'
import Router, { useRouter } from 'next/router'
import { Helmet } from 'react-helmet'

import Analytics from 'src/utils/analytics'
import PasswordProtect from 'src/components/PasswordProtect/PasswordProtect'
import CentraCart from 'src/components/CentraCart/CentraCart'
import CookieConsent from 'src/components/CookieConsent/CookieConsent'
import CountrySelector from 'src/components/CountrySelector/CountrySelector'
import ErrorBar from 'src/components/ErrorBar/ErrorBar'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import Favorites from 'src/components/Favorites/Favorites'
import Footer from 'src/components/Footer/Footer'
import Header from 'src/components/Header/Header'
import Preview from 'src/components/Preview/Preview'
import StoresProvider from 'src/stores/Provider'
import messages from 'src/stores/error/messages'
import useStore from 'src/stores/useStore'
import { getMessagesByLocale } from 'src/utils/intl'
import { setCentraLanguage } from 'src/utils/centraapi'
import globalJson from 'src/json/globalData.json' /* eslint-disable-line */
// Next.js only allows CSS imports in the Custom App (not in components)
// More here: https://nextjs.org/docs/messages/css-global
import 'src/checkout/styles/index.scss'
import 'src/styles/globals.scss'
import 'src/styles/checkout.scss'

import FAQMenu from 'src/components/FAQ/FAQMenu'
import LoginMenu from 'src/components/LoginMenu/LoginMenu'
// import Newsletter from 'src/components/Newsletter/Newsletter'
import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/utils/seo'
import Newsletter from 'src/components/Newsletter/Newsletter'

import type { AppProps } from 'next/app'
import type { NextPage } from 'next'
import type { ContentfulSiteConfig } from 'src/apollo/types/index'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}
type AinoAppProps = AppProps & {
  Component: NextPageWithLayout
  __N_PREVIEW: boolean
}
const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID
const LIPSCORE_API_KEY = process.env.NEXT_PUBLIC_LIPSCORE_API_KEY

const theme = {
  breakpoints: ['374px', '767px', '1024px', '1099px', '1920px'],
  space: [...Array(90)].map((_, i) => i * 4),
}

const reactIntlError = (error: any) => {
  if (error && error.code === 'MISSING_TRANSLATION') {
    return
  }
  console.error(error)
}

function AinoApp({ Component, __N_PREVIEW, pageProps }: AinoAppProps) {
  const getLayout = Component.getLayout ?? ((page) => page)
  const { cart, error } = useStore()
  const router = useRouter()
  const { asPath } = router
  const { locale, defaultLocale } = router
  const cachedGlobalData: any = globalJson
  const globalData = cachedGlobalData
    ? cachedGlobalData[locale ? locale : 'en']
    : {}

  useEffect(() => {
    const loadPolyfills = async () => {
      const { polyfill } = await import('src/utils/polyfills')
      await polyfill()
    }
    loadPolyfills()
  }, [])

  useEffect(() => {
    // Fix for scroll restoration when navigating backwards.
    window.history.scrollRestoration = 'manual'

    const cachedScroll: any = []

    router.events.on('routeChangeStart', () => {
      document.body.classList.remove('no-scroll')
      cachedScroll.push([window.scrollX, window.scrollY])
    })

    router.beforePopState(() => {
      const positions = cachedScroll.pop()
      if (positions) {
        const [x, y] = positions
        setTimeout(() => window.scrollTo(x, y), 100)
      } else {
        setTimeout(() => window.scrollTo(0, 0), 100)
      }

      return true
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    Analytics.pageStart(`${SITE_URL}${asPath}`, true)
    Analytics.pageEnd()
  }, [])

  useEffect(() => {
    const routeChangeStart = (slug: string) => {
      Analytics.pageStart(`${SITE_URL}${slug}`, false, `${SITE_URL}${asPath}`)
    }
    const routeChangeEnd = () => {
      Analytics.pageEnd()
    }
    Router.events.on('routeChangeStart', routeChangeStart)
    Router.events.on('routeChangeComplete', routeChangeEnd)
    Router.events.on('routeChangeError', routeChangeEnd)
    return () => {
      Router.events.off('routeChangeStart', routeChangeStart)
      Router.events.off('routeChangeComplete', routeChangeEnd)
      Router.events.off('routeChangeError', routeChangeEnd)
    }
    // setTimeout(() => inject(), 3000)
  }, [asPath])

  useEffect(() => {
    if (!cart.token || !locale) {
      return
    }
    const updateCentraLanguage = async () => {
      try {
        await setCentraLanguage(cart.token, locale)
      } catch (e: any) {
        error.log(messages.setLanguage, e)
        console.error(
          `Response error when setting centra language to ${locale}`
        )
      }
    }
    updateCentraLanguage()
  }, [cart.token, locale])

  const siteConfig: ContentfulSiteConfig = globalData.contentfulSiteConfig
  const faqConfig = globalData.contentfulFaqPages
  const {
    freeFreight,
    upsellProducts,
    newsletterPopupText,
    globalSEO,
    headerNavItems,
    headerFaqReference,
    headerPopularSearches,
    headerUspBar,
    footerMenus,
    footerNewsletter,
  } = siteConfig
    ? siteConfig
    : {
        freeFreight: [],
        upsellProducts: [],
        newsletterPopupText: null,
        globalSEO: null,
        headerNavItems: null,
        headerFaqReference: null,
        headerPopularSearches: null,
        headerUspBar: null,
        footerMenus: null,
        footerNewsletter: null,
      }
  const intlMessages = getMessagesByLocale(locale || defaultLocale)

  const { seoTitle, seoDescription, seoImage } = getSeo(globalSEO)
  return (
    <ErrorBoundary>
      <IntlProvider
        defaultLocale={defaultLocale}
        locale={locale ? locale : defaultLocale ? defaultLocale : ''}
        messages={intlMessages}
        onError={reactIntlError}
      >
        <StoresProvider>
          <Helmet
            titleTemplate={`%s | ${seoTitle}`}
            defaultTitle={seoTitle ? seoTitle : 'Emma S'}
          ></Helmet>
          <SEO
            lang={locale}
            seoMetaDescription={seoDescription ? seoDescription : ''}
            seoOgImage={seoImage ? seoImage : ''}
            type="website"
          />
          <ThemeProvider theme={theme}>
            <Head>
              <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
              <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
              <link
                rel="mask-icon"
                href="/safari-pinned-tab.svg"
                color="#5bbad5"
              />
              <script
                dangerouslySetInnerHTML={{
                  __html: `document.documentElement.className='js'`,
                }}
              />
              <script
                dangerouslySetInnerHTML={{
                  __html: `
                var avif = new Image()
                avif.src = 'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A='
                avif.onload = function () { document.documentElement.classList.add('avif') }
                avif.onerror = function () {
                  var webp = new Image()
                  webp.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA=='
                  webp.onload = function () { document.documentElement.classList.add('webp') }
                }
                `,
                }}
              />
            </Head>
            <Script
              id="Cookiebot"
              src="https://consent.cookiebot.com/uc.js"
              data-cbid="6710a2db-26ca-44af-b700-c7e4019b3704"
              data-blockingmode="auto"
              type="text/javascript"
              strategy="afterInteractive"
            />
            <Script
              strategy="afterInteractive"
              src="https://cdn.streamify.io/liveshopping.min.js"
            ></Script>
            <ErrorBar />
            {LIPSCORE_API_KEY && (
              <Fragment>
                <Script
                  id="lipscore-init"
                  dangerouslySetInnerHTML={{
                    __html: `window.lipscoreInit = function() { lipscore.init({ apiKey: '${LIPSCORE_API_KEY}' }) };`,
                  }}
                />
                <Script
                  strategy="afterInteractive"
                  src={`https://static.lipscore.com/assets/${
                    locale || defaultLocale
                  }/lipscore-v1.js`}
                />
              </Fragment>
            )}
            {GTM_ID && (
              <Fragment>
                <noscript>
                  <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                  />
                </noscript>
                <Script
                  id="gtm-init"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${GTM_ID}');
                  `,
                  }}
                />
              </Fragment>
            )}

            {/*
              Components with their own layout gets mounted outside of site-wrapper.
              They have access to theme and stores, but do not have header, footer etc
            */}
            {Component.getLayout ? (
              getLayout(<Component {...pageProps} />)
            ) : (
              <div id="site-wrapper">
                <Header
                  uspBar={headerUspBar}
                  popularSearches={headerPopularSearches}
                  navItems={headerNavItems}
                  faqRef={headerFaqReference}
                />
                <CentraCart
                  upsellProducts={upsellProducts}
                  freeFreight={freeFreight}
                />
                <FAQMenu faqConfig={faqConfig} />
                <Favorites />
                <LoginMenu />
                <CountrySelector />
                {newsletterPopupText && (
                  <Newsletter newsletterText={newsletterPopupText} isPopup />
                )}
                <Preview isPreview={__N_PREVIEW} />
                <main id="aino-content">
                  <Component {...pageProps} />
                </main>
                <Footer
                  navLinks={footerMenus}
                  newsletterText={footerNewsletter}
                />
              </div>
            )}
          </ThemeProvider>
        </StoresProvider>
      </IntlProvider>
    </ErrorBoundary>
  )
}

export default AinoApp
