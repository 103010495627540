import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import { storageKeys } from 'src/constants'

import Checkbox from '../UI/Checkbox/Checkbox'
import { Flex, Box } from '../UI/Grid/Grid'
import { CloseIcon } from '../UI/Icons/Icons'
import Input from '../UI/Input/Input'
import PrimaryButton from '../UI/PrimaryButton/PrimaryButton'
import styles from './NewsletterPopup.module.scss'

const NewsletterPopup = ({
  open,
  setOpen,
  newsletterText,
  handleChange,
  handleSubmit,
  hasConsent,
  consentText,
  setHasConsent,
  feedbackMessage,
  email,
  emailPlaceholder,
  subscribed,
}: any) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.form
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          id="newsletter-popup"
          // transition={{ duration: 1 }}
          className={styles.popup}
          onSubmit={handleSubmit}
        >
          <Flex
            alignItems="center"
            className={styles.close}
            onClick={() => {
              window.localStorage.setItem(storageKeys.POPUP_CLOSED, 'true')
              setOpen(false)
            }}
            as="button"
          >
            <CloseIcon width={13} height={13} />
          </Flex>
          <Box marginBottom={[4]} as="h2">
            <FormattedMessage
              defaultMessage="Emma S. World"
              id="0aNtKo"
              description="Heading for newsletter"
            />
          </Box>
          <Box
            width={[5 / 6]}
            className={styles.newsletterText}
            as="p"
            marginBottom={[10]}
          >
            {newsletterText}
          </Box>
          <Box className={styles.inputContainer}>
            <Box marginBottom={4}>
              <Input
                name="email"
                onChange={handleChange}
                value={email}
                placeholder={emailPlaceholder}
              />
            </Box>
            <PrimaryButton className={styles.subscribeButton}>
              <FormattedMessage
                defaultMessage="Sign up"
                id="H4ayLI"
                description="Text for newsletter 'Subscribe' button"
              />
            </PrimaryButton>
          </Box>
          <Box className={styles.consent}>
            <Checkbox
              name={'newsletter-popup-consent'}
              checked={hasConsent}
              onChange={() => setHasConsent(!hasConsent)}
              noCheckedBorder
              round
            >
              {consentText}
            </Checkbox>
          </Box>
          {feedbackMessage && (
            <Box
              as="p"
              className={classNames(styles.feedbackMessage, {
                [styles.errorMessage]: !subscribed,
              })}
            >
              {feedbackMessage}
            </Box>
          )}
        </motion.form>
      )}
    </AnimatePresence>
  )
}

export default NewsletterPopup
