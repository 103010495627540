import { FormattedMessage, useIntl } from 'react-intl'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

import useStore from 'src/stores/useStore'
import { Box, Flex } from 'src/components/UI/Grid/Grid'
import SlideOver from 'src/components/SlideOver/SlideOver'
import { ChevronDown, CloseIcon, PlusIcon } from 'src/components/UI/Icons/Icons'
import { getIntlUrl } from 'src/utils/intl'

import styles from './CountrySelector.module.scss'

const countryCodeToName = (code: string) => {
  switch (code) {
    case 'sv':
      return 'Swedish'
    case 'en':
      return 'English'
    default:
      return code
  }
}

const CountrySelector = () => {
  const intl = useIntl()
  const router = useRouter()
  const { cart, ui } = useStore()
  const [activeMenu, setActiveMenu] = useState(-1)
  const [isMounted, setIsMounted] = useState<boolean>(false)

  useEffect(() => {
    if (typeof window === 'undefined' || isMounted) {
      return
    }
    setIsMounted(true)
  }, [isMounted])

  const setLocaleCookie = (newLocale: string) => {
    const date = new Date()
    const expireMs = 100 * 24 * 60 * 60 * 1000 // 100 days
    date.setTime(date.getTime() + expireMs)
    document.cookie = `NEXT_LOCALE=${newLocale};expires=${date.toUTCString()};path=/`
  }

  const activeCountry = cart.country
  const { locales, locale, pathname, query, asPath } = router

  return isMounted ? (
    <SlideOver
      active={ui.activeMenu === 'country'}
      onCloseClick={() => {
        ui.setActiveMenu('')
      }}
      onClick={(e: any) => {
        if (e.target.nodeName !== 'BUTTON') {
          setActiveMenu(-1)
        }
      }}
      className={
        ui.activeMenu === 'country'
          ? [styles.wrapper, styles.active].join(' ')
          : styles.wrapper
      }
    >
      <Flex
        paddingX={[5, null, null, 10]}
        paddingTop={[10]}
        paddingBottom={'10vw'}
        width={1}
        flexDirection="column"
      >
        <Flex
          marginBottom={[10]}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box className={styles.title} as="span">
            {/* <FormattedMessage
              defaultMessage="Language & Country"
              id="Rya8VL"
              description="Title for language menu"
            /> */}
            Language & Country
          </Box>
          <Flex
            alignItems="center"
            onClick={() => {
              ui.setActiveMenu('')
            }}
            className={styles.closeIcon}
            as="button"
            aria-label={intl.formatMessage({
              defaultMessage: 'Close country selector window',
              id: 'w51Bd3',
              description:
                'Label for button that closes the country selector window',
            })}
          >
            <PlusIcon />
          </Flex>
        </Flex>
        <Flex marginBottom={[4]} flexDirection="column">
          <Box marginBottom={[3]} className={styles.label} as="span">
            {/* <FormattedMessage
              defaultMessage="Language"
              id="7U5W7I"
              description="Label for language select"
            /> */}
            Language
          </Box>
          <Box
            className={
              activeMenu === 0
                ? [styles.selectWrapper, styles.active].join(' ')
                : styles.selectWrapper
            }
          >
            <Box
              onClick={() =>
                activeMenu !== 0 ? setActiveMenu(0) : setActiveMenu(-1)
              }
              className={styles.select}
              as="button"
            >
              {locale ? countryCodeToName(locale) : ''}
            </Box>
            <Flex className={styles.values} flexDirection="column">
              {locales?.map((localeString) => {
                return (
                  <Flex
                    paddingX={[5]}
                    height="40px"
                    alignItems="center"
                    justifyContent="flex-start"
                    as="button"
                    className={styles.value}
                    key={localeString}
                    onClick={() => {
                      setLocaleCookie(localeString)
                      // const newUrl = getIntlUrl(location.href, localeString)
                      // location.href = newUrl

                      router
                        .push({ pathname, query }, asPath, {
                          locale: localeString,
                        })
                        .then(() => {
                          router.reload()
                        })
                      setActiveMenu(-1)
                      setTimeout(() => {
                        ui.setActiveMenu('')
                      }, 1000)
                    }}
                  >
                    {countryCodeToName(localeString)}
                  </Flex>
                )
              })}
            </Flex>
            <ChevronDown />
          </Box>
        </Flex>
        {/* Country */}
        {cart && cart.countries && cart.countries.length > 0 && (
          <Flex flexDirection="column">
            <Box marginBottom={[3]} className={styles.label} as="span">
              {/* <FormattedMessage
                defaultMessage="Country"
                id="+lqZs1"
                description="Label for country select"
              /> */}
              Country
            </Box>
            <Box
              className={
                activeMenu === 1
                  ? [styles.selectWrapper, styles.active].join(' ')
                  : styles.selectWrapper
              }
            >
              <Box
                onClick={() =>
                  activeMenu !== 1 ? setActiveMenu(1) : setActiveMenu(-1)
                }
                className={styles.select}
                as="button"
              >
                {activeCountry
                  ? `${activeCountry.name} (${activeCountry.currency})`
                  : ''}
              </Box>
              <Flex className={styles.values} flexDirection="column">
                {cart.countries?.map((value: any) => {
                  return (
                    <Flex
                      paddingX={[5]}
                      height="40px"
                      alignItems="center"
                      justifyContent="space-between"
                      as="button"
                      className={styles.value}
                      key={value.country}
                      onClick={() => {
                        cart.setCountry(value.country)
                        setTimeout(() => {
                          window.location.reload()
                        }, 1000)
                        setActiveMenu(-1)
                      }}
                    >
                      <Box as="span">{value.name}</Box>
                      <Box className={styles.currency} as="span">
                        {value.currency}
                      </Box>
                    </Flex>
                  )
                })}
              </Flex>
              <ChevronDown />
            </Box>
          </Flex>
        )}
      </Flex>
    </SlideOver>
  ) : null
}

export default observer(CountrySelector)
