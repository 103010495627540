export const storageKeys = {
  CART: 'cart',
  COOKIE_ACCEPTED: 'cookie-accepted',
  DEFAULT_FIT: 'default-fit',
  ORDER: 'order',
  ORDERS: 'orders',
  PREVIEW: 'preview',
  SIZE_FORMAT: 'size-format',
  TRACKED_ORDERS: 'tracked-orders',
  FAVORITES: 'emma-fav',
  POPUP_CLOSED: 'popup-closed',
}
