import { action, computed, makeObservable, observable, reaction } from 'mobx'
import cookieCutter from 'cookie-cutter'
import Cookies from 'cookies'

import {
  addItem,
  changeCountry,
  createCart,
  loginCentraUser,
  logoutCentraUser,
  registerCentraUser,
  getCentraUser,
  getCentraUserOrders,
  sendCentraResetPasswordEmail,
  resetCentraPassword,
  refreshCart,
  removeItem,
  subscribeNewsletter,
  updateItemQuantity,
} from 'src/utils/centraapi'
import { storageKeys } from 'src/constants'

import { CartType, Country, PendingType } from './types'

export const NODE_COUNTRY = 'US'

export default class Cart {
  canonicals: string[] = []
  cart: CartType = null
  favorites: string[] = []
  inchActive = false
  pending: PendingType = true
  req: any = null
  res: any = null

  static getCartBackend(req: any, res: any) {
    const cookies = new Cookies(req, res)
    const token = cookies.get('cart-token')
    if (token) {
      return refreshCart(token)
    }
  }

  constructor(newReq?: any, newRes?: any) {
    makeObservable(this, {
      clear: action.bound,
      loginUser: action.bound,
      setCanonicals: action.bound,
      setCartLoading: action.bound,
      setCountry: action.bound,
      setInchActive: action.bound,
      subscribeNewsletter: action.bound,
      updateCart: action.bound,
      updateFavorites: action.bound,

      count: computed,
      countries: computed,
      country: computed,
      countryCode: computed,
      favoriteCount: computed,
      items: computed,
      locale: computed,
      market: computed,
      pricelist: computed,
      token: computed,
      totalPrice: computed,
      totalShipping: computed,

      canonicals: observable,
      cart: observable,
      favorites: observable,
      inchActive: observable,
      pending: observable,
      req: observable,
      res: observable,
    })

    if (newReq && newRes) {
      this.req = newReq
      this.res = newRes
    }

    if (typeof window === 'undefined') {
      // not tested Server-Side but has theoretical support via Cookies
      // localStorage & cookies might need to be synced though in some way
      return
    }

    const savedCart = this.getStorageCart(this.req, this.res)
    const savedFavorites = this.getStorageFavorites(this.req, this.res)

    if (this.getStorageSizeFormat(this.req, this.res) === 'inches') {
      this.inchActive = true
    }

    ;(async () => {
      try {
        if (savedCart) {
          this.updateCart(JSON.parse(savedCart))
          this.updateCart(await refreshCart(this.token))
        } else {
          this.updateCart(await createCart())
        }
        if (savedFavorites) {
          this.updateFavorites(JSON.parse(savedFavorites))
        }
        this.saveTokenCookie()
      } catch (e) {
        console.error(e)
      }
    })()

    reaction(
      () => this.cart,
      (nextCart: CartType) => {
        this.setStorageCart(nextCart, newReq, newRes)
      }
    )
  }

  saveTokenCookie(): void {
    if (!process.browser) {
      return
    }
    cookieCutter.set('cart-token', this.token, {
      path: '/',
    })
  }

  getStorageCart(req?: any, res?: any) {
    const storageKey = 'cart'
    if (process.browser) {
      return localStorage.getItem(storageKey)
    }
    if (req && res) {
      const cookies = new Cookies(req, res)
      return cookies.get(storageKey)
    }
    return null
  }

  getStorageFavorites(req?: any, res?: any) {
    const storageKey = storageKeys.FAVORITES
    if (process.browser) {
      return localStorage.getItem(storageKey)
    }
    if (req && res) {
      const cookies = new Cookies(req, res)
      return cookies.get(storageKey)
    }
    return null
  }

  updateFavorites(favorites: string[]) {
    this.favorites = favorites
    const storageKey = storageKeys.FAVORITES
    const value = JSON.stringify(favorites)
    if (process.browser) {
      return localStorage.setItem(storageKey, value)
    }
  }

  async toggleFavorite(favorite: string) {
    const storageFavorites = this.getStorageFavorites()
    let favorites = []

    if (storageFavorites) {
      favorites = JSON.parse(storageFavorites)
    }

    if (favorites) {
      const item = favorites.find((fav: any) => fav === favorite)
      if (item) {
        favorites = favorites.filter((fav: any) => fav !== favorite)
      } else {
        favorites.push(favorite)
      }
      this.updateFavorites(favorites)
    }
  }

  isFavorite(id: any) {
    if (!id) {
      return false
    }

    const item = this.favorites.find((fav: any) => fav === id)
    if (item) {
      return true
    }
    return false
  }

  getStorageSizeFormat(req?: any, res?: any) {
    const storageKey = storageKeys.SIZE_FORMAT
    if (process.browser) {
      return localStorage.getItem(storageKey)
    }
    if (req && res) {
      const cookies = new Cookies(req, res)
      return cookies.get(storageKey)
    }
    return null
  }

  setStorageCart(nextCart: any, req?: any, res?: any) {
    const storageKey = 'cart'
    const value = JSON.stringify(nextCart)
    if (process.browser) {
      return localStorage.setItem(storageKey, value)
    }
    if (req && res) {
      const cookies = new Cookies(req, res)
      return cookies.set(storageKey, value, {
        httpOnly: false,
      })
    }
    return null
  }

  async addItem(item: any): Promise<void> {
    this.setCartLoading('adding')
    this.updateCart(await addItem(this.token, item.item))
  }

  async removeItem(item: string): Promise<void> {
    this.setCartLoading('removing')
    this.updateCart(await removeItem(this.token, item))
  }

  async updateQuantity(line: string, quantity: string): Promise<void> {
    this.setCartLoading('updating')
    this.updateCart(await updateItemQuantity(this.token, line, quantity))
  }

  setCanonicals(items: any): void {
    items.forEach((item: any) => {
      this.canonicals[item.sku] = item.canonicalUri
    })
  }

  setInchActive(status: boolean): void {
    if (process.browser) {
      localStorage.setItem(storageKeys.SIZE_FORMAT, status ? 'inches' : 'cm')
    } else if (this.req && this.res) {
      const cookies = new Cookies(this.req, this.res)
      cookies.set(storageKeys.SIZE_FORMAT, status ? 'inches' : 'cm', {
        httpOnly: false,
      })
    }
    this.inchActive = status
  }

  setCartLoading(pendingType: PendingType): void {
    console.log(`Cart status is: ${pendingType}`); /* eslint-disable-line */
    this.pending = pendingType
  }

  updateCart(cart: CartType): void {
    this.pending = false
    let sizeFormat
    if (process.browser) {
      sizeFormat = localStorage.getItem(storageKeys.SIZE_FORMAT)
    } else if (this.req && this.res) {
      const cookies = new Cookies(this.req, this.res)
      sizeFormat = cookies.get(storageKeys.SIZE_FORMAT)
    }
    // Check if user is from US and set default size format if not already choosen.
    if (
      !sizeFormat &&
      cart &&
      cart.location &&
      cart.location.country === 'US'
    ) {
      this.setInchActive(true)
    }

    this.cart = cart
  }

  async setCountry(country: string): Promise<void> {
    this.updateCart(await changeCountry(this.token, country))
  }

  async clear(): Promise<void> {
    // TODO: do this correctly
    this.updateCart(await createCart())
  }

  async loginUser(email: string, password: string): Promise<void | boolean> {
    const response = await loginCentraUser(this.token, email, password)
    if (response) {
      this.updateCart(response)
    } else {
      return false
    }
  }
  async registerUser({
    email,
    password,
    firstName,
    lastName,
    locale,
  }: {
    email: string
    password: string
    firstName: string
    lastName: string
    locale: string
  }): Promise<void | boolean> {
    const response = await registerCentraUser({
      token: this.token,
      email,
      password,
      firstName,
      lastName,
      locale,
    })
    if (response) {
      this.updateCart(response)
    } else {
      return false
    }
  }
  async getUser(): Promise<void | boolean> {
    const response = await getCentraUser(this.token)
    if (response) {
      return response
    } else {
      return false
    }
  }
  async getUserOrders(): Promise<void | boolean> {
    const response = await getCentraUserOrders(this.token)
    if (response) {
      return response
    } else {
      return false
    }
  }
  async logoutUser(): Promise<void | boolean> {
    const response = await logoutCentraUser(this.token)
    if (response) {
      this.updateCart(response)
    } else {
      return false
    }
  }
  async sendResetPasswordEmail(
    email: string,
    linkUri: string
  ): Promise<void | boolean> {
    return await sendCentraResetPasswordEmail(this.token, email, linkUri)
  }

  async resetPassword(
    i: string,
    id: string,
    newPassword: string
  ): Promise<void | boolean> {
    const response = await resetCentraPassword(this.token, i, id, newPassword)
    if (response) {
      this.updateCart(response)
    } else {
      return false
    }
  }

  async subscribeNewsletter({
    email,
    locale,
  }: {
    email: string
    locale: string
  }): Promise<void | boolean> {
    const response = await subscribeNewsletter({
      token: this.token,
      email: email,
      locale: locale,
    })
    if (response) {
      return response
    } else {
      return false
    }
  }

  get count(): number {
    if (!this.cart) {
      return 0
    }
    return this.cart.selection?.items
      .map((item: any) => item.quantity)
      .reduce((a, b) => a + b, 0)
  }

  get favoriteCount(): number {
    if (!this.favorites) {
      return 0
    }
    return this.favorites.length
  }

  get isLoggedIn(): boolean {
    return this.cart?.loggedIn ? true : false
  }

  get currency(): string {
    return this.country ? this.country.currency : ''
  }

  get pricelist(): number {
    if (!this.cart) {
      return 0
    }
    return this.cart.location.pricelist
  }

  get items(): Record<string, unknown>[] {
    if (!this.cart) {
      return []
    }
    return this.cart.selection?.items
  }

  get totalPrice(): string {
    if (!this.cart) {
      return ''
    }
    return this.cart.selection?.totals.grandTotalPrice
  }

  get itemsTotalPrice(): number {
    if (!this.cart) {
      return 0
    }

    return this.cart.selection.totals.itemsTotalPriceAsNumber
  }

  get totalShipping(): string {
    if (!this.cart) {
      return ''
    }
    return this.cart.selection?.totals.shippingPrice
  }

  get countries(): Record<string, unknown>[] {
    if (!this.cart) {
      return []
    }
    return this.cart.countries
  }

  get token(): string {
    return this.cart ? this.cart.token : ''
  }

  get countryCode(): string | null {
    if (!process.browser) {
      return NODE_COUNTRY
    }
    return this.cart ? this.cart.location.country : null
  }

  get country(): Country | null {
    return this.cart
      ? this.cart.countries.find(
          (country: Country) => country.country === this.countryCode
        ) || null
      : null
  }

  get market(): string | null {
    if (!process.browser) {
      return null
    }
    return this.cart ? this.cart.location.market.toString() : null
  }

  get locale(): string | null {
    if (!process.browser) {
      return 'en'
    }
    if (this.cart) {
      const country =
        this.cart.countries.find(
          (country: any) =>
            this.cart && country.country === this.cart.location.country
        ) || null
      return country ? country.language : null
    }
    return null
  }

  getSelectedCountry = (
    countries: Country[]
  ): Record<string, unknown> | null => {
    if (!countries) return null
    if (this.cart && this.cart.location.country) {
      const filteredCountries = countries
        .filter(
          (country: Country) => country.country === this.cart!.location.country
        )
        .map((country: Country) => ({
          value: country.country,
          label: country.name,
        }))
      if (filteredCountries && filteredCountries.length > 0) {
        return filteredCountries[0]
      }
    }
    return null
  }

  getAllCountries = (
    countries: Country[]
  ): Record<string, unknown>[] | null => {
    if (!countries) return null
    const response = countries
      // .filter((country: any) => country.shipTo)
      .map((country: any) => ({
        value: country.country,
        label: country.name,
      }))
    return response
  }

  async refresh(): Promise<void> {
    this.updateCart(await refreshCart(this.token))
  }
}
