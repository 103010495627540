import { FormattedMessage, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import PrimaryButton from 'src/components/UI/PrimaryButton/PrimaryButton'
import forgotPasswordSchema from 'src/schemas/forgot-password'
import messages from 'src/stores/error/messages'
import useStore from 'src/stores/useStore'
import { Box, Flex } from 'src/components/UI/Grid/Grid'

import styles from './ForgotPasswordForm.module.scss'

const ForgotPasswordForm = ({ isMenu }: { isMenu?: boolean }) => {
  const { cart, error } = useStore()
  const intl = useIntl()
  const [sentResetPassword, setSentResetPassword] = useState<boolean | null>(
    null
  )

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  })

  const onSubmit = async (data: any) => {
    const { email } = data
    try {
      await cart.sendResetPasswordEmail(email, 'reset-password')
      setSentResetPassword(true)
    } catch (e: any) {
      error.warn(messages.sendResetPasswordLink, e)
    }
  }

  return (
    <Flex
      paddingBottom={isMenu ? 0 : 20}
      paddingX={[5]}
      width={1}
      maxWidth={['none', null, isMenu ? 'none' : '400px']}
      alignItems="center"
      flexDirection="column"
      className={styles.forgotPassword}
    >
      <Box marginBottom={[9]} as="h1" className={styles.title}>
        <FormattedMessage
          defaultMessage="Forgot password"
          id="hOdMDD"
          description="Title text on forgot password page"
        />
      </Box>
      {!sentResetPassword ? (
        <>
          <Flex
            maxWidth={['none', null, '400px']}
            marginBottom={[11]}
            flexDirection="column"
            as="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              {...register('email')}
              aria-invalid={errors.email ? 'true' : 'false'}
              placeholder={intl.formatMessage({
                defaultMessage: 'Your e-mail',
                id: 'gqgzb5',
                description:
                  'Placeholder text for forgot password page e-mail address input',
              })}
            />
            {errors?.email && (
              <p className={styles.error}>
                <FormattedMessage
                  defaultMessage="email must be a valid email"
                  id="Y+qrb5"
                  description="Error message in reset password form for email"
                />
              </p>
            )}
            <PrimaryButton>
              <FormattedMessage
                defaultMessage="Reset password"
                id="kwzxhM"
                description="Text on reset button on forgot password page"
              />
            </PrimaryButton>
          </Flex>
          {!isMenu && (
            <Flex className={styles.links}>
              <Flex>
                <LinkComponent className={styles.link} href="/login">
                  <FormattedMessage
                    defaultMessage="Cancel"
                    id="KaP/Yr"
                    description="A link text on forgot password page"
                  />
                </LinkComponent>
              </Flex>
            </Flex>
          )}
        </>
      ) : (
        <Flex as="p" className={styles.confirmation}>
          <FormattedMessage
            defaultMessage="Reset link sent. Please check your inbox."
            id="hnERDM"
            description="Confirmation text that shows when reset password link has been sent"
          />
        </Flex>
      )}
    </Flex>
  )
}

export default ForgotPasswordForm
