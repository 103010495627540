import { action, makeObservable, observable } from 'mobx'

import { Menu } from './types'

export default class Ui {
  activeMenu: Menu = ''
  forceRefresh = false
  pageLoading = 0
  windowHeight = 0
  windowWidth = 0

  constructor() {
    makeObservable(this, {
      setActiveMenu: action.bound,
      setForceRefresh: action.bound,
      setPageLoading: action.bound,
      setWindowSize: action.bound,

      activeMenu: observable,
      forceRefresh: observable,
      pageLoading: observable,
      windowHeight: observable,
      windowWidth: observable,
    })
  }

  setWindowSize({ width, height }: { width: number; height: number }) {
    this.windowWidth = width
    this.windowHeight = height
  }

  setForceRefresh() {
    this.forceRefresh = true
  }

  setActiveMenu(menu: Menu) {
    this.activeMenu = menu
  }

  setPageLoading(pageLoading: number) {
    this.pageLoading = pageLoading
  }
}
