import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import Tag from 'src/components/Tag/Tag'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import { getCentraSrcSet } from 'src/utils/image'

import styles from './SearchProduct.module.scss'

import type { AinoProduct, Maybe } from 'src/apollo/types'
import type { CentraProduct } from 'src/types'

type Props = {
  product: AinoProduct | CentraProduct
  onClick?: any
}

const SearchProduct = ({ product, onClick }: Props): JSX.Element => {
  const {
    categories,
    items,
    media,
    name,
    price,
    priceAsNumber,
    priceBeforeDiscount,
    primaryImage,
    showAsOnSale,
    sku,
    productTags,
  } = product
  const hoverImage =
    media && Array.isArray(media) && media.length > 1 && media[1]
      ? media[1]
      : null
  const clonedProd: any = { ...product }
  const slug = clonedProd.slug ? clonedProd.slug : clonedProd.uri

  return (
    <Box className={styles.card}>
      <LinkComponent
        onClick={() => {
          if (onClick) {
            onClick()
          }
        }}
        href={`/product/${slug}`}
      >
        <Flex flexDirection={['row', null, null, 'column']} width={1}>
          {productTags && productTags.length > 0 && (
            <Box as="span" className={styles.tag}>
              {productTags.map(
                (tag: Maybe<string>) =>
                  tag && <Tag size="small" title={tag} key={tag} />
              )}
            </Box>
          )}
          <Box
            marginBottom={[0, null, null, 4]}
            width={['100px', null, null, 1]}
            marginRight={[4]}
            className={styles.imageWrapper}
          >
            {primaryImage && (
              <Image
                srcSet={getCentraSrcSet(primaryImage)}
                alt={name ? name : ''}
              />
            )}
            {hoverImage && (
              <Image
                className={styles.hoverImage}
                srcSet={getCentraSrcSet(hoverImage)}
                alt={name ? name : ''}
              />
            )}
          </Box>
          <Flex width={1} flexDirection="column">
            <Box className={styles.name} marginBottom={1} as="span">
              {name}
            </Box>
            <Flex>
              {showAsOnSale && (
                <Box marginRight={2} className={styles.old} as="span">
                  {priceBeforeDiscount}
                </Box>
              )}
              <Box
                className={showAsOnSale ? styles.sale : styles.price}
                as="span"
              >
                {price}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </LinkComponent>
    </Box>
  )
}

export default SearchProduct
