import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import useStore from 'src/stores/useStore'
import { CloseIcon } from 'src/components/UI/Icons/Icons'

import styles from './ErrorBar.module.scss'

const ErrorBar = () => {
  const { error } = useStore()
  const show = error && error.message
  const [isMounted, setIsMounted] = useState<boolean>(false)

  useEffect(() => {
    if (!process.browser || isMounted) {
      return
    }
    setIsMounted(true)
  }, [isMounted])

  return isMounted ? (
    <div className={[styles.errorWrapper, show ? styles.show : ''].join(' ')}>
      <p>{error.message ? <FormattedMessage {...error.message} /> : ''}</p>
      <button
        aria-label="Close message"
        className={styles.close}
        onClick={error.clearMessage}
      >
        <CloseIcon color="#fbf9f8" width={16} height={16} />
      </button>
    </div>
  ) : null
}

export default observer(ErrorBar)
