import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'

import useStore from 'src/stores/useStore'
import { CentraProduct } from 'src/types'
import { getCentraProducts } from 'src/utils/category'
import { parseCentraProductToAinoProduct } from 'src/utils/parser'

import type { AinoProduct, Maybe } from 'src/apollo/types'
import type { Props } from './types'

export type FilterTypes = {
  categories?: string[]
  collections?: string[]
  ainoSkintype?: string[]
  ainoRoutine?: string[]
  ainoSkinConcern?: string[]
  ainoProductFormula?: string[]
  ainoOther?: string[]
  'ainoKeyIngredient.name'?: string[]
  ainoSampleProduct_boolean?: any[]
  ainoGiftProduct_boolean?: any[]
  search?: string
  sort?: string
}

const getDefaultFilter = () => ({
  categories: [],
  collections: [],
  ainoSkintype: [],
  ainoRoutine: [],
  ainoSkinConcern: [],
  ainoProductFormula: [],
  ainoOther: [],
  'ainoKeyIngredient.name': [],
  search: '',
  sort: '',
})

// Use this component to fetch products
// If you want to filter on speceific products, pass a
// renderResults function to render out the result
const ProductsContainer = ({ products, listOnly, renderResult }: Props) => {
  const { cart } = useStore()
  const intl = useIntl()

  const [activeFilter, setActiveFilter]: any = useState(getDefaultFilter())
  const [centraFilter, setCentraFilter] = useState([])
  const [centraProducts, setCentraProducts] = useState<
    AinoProduct[] | Maybe<AinoProduct>[]
  >([])

  useEffect(() => {
    if (products && products.length > 0 && centraProducts.length === 0) {
      setCentraProducts(products)
    }
  }, [products])

  const sortOptions = [
    {
      title: intl.formatMessage({
        defaultMessage: 'Popular',
        id: 'SWekH9',
        description: 'Title for popular sorting',
      }),
      value: '',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Lowest price',
        id: 'pwfUFY',
        description: 'Title for lowest price sorting',
      }),
      value: 'priceAsc',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Highest price',
        id: 'Phk4IF',
        description: 'Title for highest price sorting',
      }),
      value: 'priceDesc',
    },
  ]

  const router = useRouter()
  const locale = router.locale

  const updateProducts = async () => {
    const options = getFilter()
    let isFiltered = false
    if (options.sort) {
      isFiltered = true
    }
    if (options && Object.keys(options).length > 0) {
      Object.keys(options).forEach((key: string) => {
        if (Array.isArray(options[key]) && options[key].length > 0) {
          options[key].forEach((activeValue: string) => {
            isFiltered = true
          })
        }
      })
    }

    const ids = products
      ? products.map((product: any) => (product ? product.id : ''))
      : []

    if (cart.token && ((ids && ids.length > 0) || options.search !== '')) {
      const centraResponse = await getCentraProducts({
        token: cart.token,
        productIds: ids,
        products: products ? products : [],
        options: options,
        locale: locale,
      })
      if (centraResponse) {
        setCentraFilter(centraResponse.filter)
        if (!isFiltered && products) {
          const liveProducts = products.map((product: Maybe<AinoProduct>) => {
            if (product) {
              const liveProduct = centraResponse.products.find(
                (p: CentraProduct) => {
                  return p.product === product.id
                }
              )
              if (liveProduct) {
                return parseCentraProductToAinoProduct(liveProduct)
              }
              return product
            } else {
              return null
            }
          })
          setCentraProducts(liveProducts)
        } else {
          setCentraProducts(
            centraResponse.products
              .filter(
                (product: CentraProduct) =>
                  product.ainoSampleProduct_boolean !== '1' &&
                  product.ainoGiftProduct_boolean !== '1'
              )
              .map((product: CentraProduct) =>
                parseCentraProductToAinoProduct(product)
              )
          )
        }
      }
    }
  }

  const onHashChange = () => {
    const filter = getFilter()
    setActiveFilter(filter)
    updateProducts()
  }

  const clearFilter = () => {
    let url = window?.location?.pathname || ''
    const query = window?.location?.search || ''
    const fromHash = queryString.parse(window.location.hash)
    let nextHash = ''
    if (fromHash.search) {
      nextHash = `search=${fromHash.search}`
    }
    url = `${url}${query}#${nextHash}`
    router.push(url)
  }

  const getFilter = () => {
    const fromHash = queryString.parse(window.location.hash)
    const nextFilter: any = getDefaultFilter()
    Object.keys(fromHash).forEach((categoryKey) => {
      if (categoryKey && nextFilter.hasOwnProperty(categoryKey)) {
        let value = fromHash[categoryKey]
        if (categoryKey === 'search' || categoryKey === 'sort') {
          nextFilter[categoryKey] = value
        } else {
          if (typeof value === 'string') {
            value = value.split(',')
          }
          if (Array.isArray(value)) {
            nextFilter[categoryKey] = value
          }
        }
      }
    })
    return nextFilter
  }

  useEffect(() => {
    const nextFilter = getFilter()
    setActiveFilter(nextFilter)
    updateProducts()

    if (!listOnly) {
      router.events.on('hashChangeComplete', onHashChange)
    }

    return () => {
      if (!listOnly) {
        router.events.off('hashChangeComplete', onHashChange)
      }
    }
  }, [locale, listOnly])

  const removeItem = (arr: any, value: any) => {
    let i = 0
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1)
      } else {
        ++i
      }
    }
    return arr
  }

  const updateHash = (): void => {
    const currentHash = window?.location?.hash || ''
    const nextHash = queryString.stringify(activeFilter, {
      arrayFormat: 'none',
      // encode: false, // This don't work for filters with & in them
    })
    if (currentHash !== nextHash) {
      let url = window?.location?.pathname || ''
      const query = window?.location?.search || ''
      url = `${url}${query}#${nextHash}`
      router.push(url)
    }
  }

  const onFilterClick = async (field: string, value: any) => {
    const currFilter = activeFilter
    if (currFilter[field]) {
      if (currFilter[field].includes(value.value)) {
        const index = currFilter[field].indexOf(value.value)
        if (index > -1) {
          const currField = removeItem(currFilter[field], value.value)
          currFilter[field] = currField
        }
      } else {
        currFilter[field].push(value.value)
      }
    }
    setActiveFilter(currFilter)
    updateHash()
  }

  const onSortClick = async (option: any) => {
    const currFilter = activeFilter
    currFilter.sort = option.value
    setActiveFilter(currFilter)
    updateHash()
  }

  return renderResult
    ? renderResult({
        centraFilter,
        activeFilter,
        products: centraProducts,
        totalHits: centraProducts.length,
        onFilterClick,
        onSortClick,
        sortOptions,
        clearFilter,
      })
    : null
}

export default ProductsContainer
