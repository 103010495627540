import { FormattedMessage, useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import signupSchema from 'src/schemas/signup'
import useStore from 'src/stores/useStore'
import messages from 'src/stores/error/messages'
import PrimaryButton from 'src/components/UI/PrimaryButton/PrimaryButton'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import PasswordInput from 'src/components/PasswordInput/PasswordInput'
import { Box, Flex } from 'src/components/UI/Grid/Grid'

import styles from './SignupForm.module.scss'

const SignupForm = ({ isMenu }: { isMenu?: boolean }) => {
  const { cart, error, ui } = useStore()
  const intl = useIntl()
  const router = useRouter()
  const { locale } = router

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(signupSchema),
  })

  const onSubmit = async (data: any) => {
    const { email, password, firstname, lastname } = data
    try {
      await cart.registerUser({
        email,
        password,
        firstName: firstname,
        lastName: lastname,
        locale: locale === 'sv' ? 'sv' : 'en',
      })
    } catch (e: any) {
      if (e.response.data.errors.email) {
        // email does already exist
        error.warn(messages.emailAlreadyRegistered, e)
        return
      }
      error.warn(messages.registerUser, e)
    }
    router.push('/profile')
    ui.setActiveMenu('')
  }

  const fetchUser = async () => {
    try {
      const response = await cart.getUser()
      if (response) {
        router.push('/profile')
      }
    } catch (e) {}
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <Flex
      paddingBottom={isMenu ? 0 : 20}
      paddingX={[5]}
      width={1}
      maxWidth={['none', null, isMenu ? 'none' : '400px']}
      justifyContent="center"
      alignItems="center"
      className={styles.signup}
      flexDirection="column"
    >
      <Box marginBottom={[9]} as="h1" className={styles.title}>
        <FormattedMessage
          defaultMessage="Create Account"
          id="uzsLZz"
          description="Title text on signup page"
        />
      </Box>
      <Flex
        maxWidth={['none', null, '400px']}
        marginBottom={[11]}
        flexDirection="column"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          {...register('email')}
          aria-invalid={errors.email ? 'true' : 'false'}
          placeholder={intl.formatMessage({
            defaultMessage: 'Your e-mail',
            id: '3/8N8U',
            description: 'Placeholder text for sign up e-mail address input',
          })}
        />
        {errors?.email && (
          <p className={styles.error}>
            <FormattedMessage
              defaultMessage="email must be a valid email"
              id="4LtjWw"
              description="Error message in signup form for email"
            />
          </p>
        )}
        <PasswordInput register={register} errors={errors} />
        {errors?.password && (
          <p className={styles.error}>
            <FormattedMessage
              defaultMessage="password must be at least 6 characters"
              id="+sny9O"
              description="Error message in signup form for password"
            />
          </p>
        )}
        <input
          {...register('firstname')}
          aria-invalid={errors.firstname ? 'true' : 'false'}
          placeholder={intl.formatMessage({
            defaultMessage: 'Your first name',
            id: 'D+4okM',
            description: 'Placeholder text for signup, first name input',
          })}
        />
        {errors?.firstname && (
          <p className={styles.error}>
            <FormattedMessage
              defaultMessage="you need to enter your first name"
              id="TRzT6V"
              description="Error message in signup form for first name"
            />
          </p>
        )}
        <input
          {...register('lastname')}
          aria-invalid={errors.lastname ? 'true' : 'false'}
          placeholder={intl.formatMessage({
            defaultMessage: 'Your last name',
            id: 'wyVylr',
            description: 'Placeholder text for signup, last name input',
          })}
        />
        {errors?.lastname && (
          <p className={styles.error}>
            <FormattedMessage
              defaultMessage="you need to enter your last name"
              id="UpBirn"
              description="Error message in signup form for last name"
            />
          </p>
        )}
        <PrimaryButton>
          <FormattedMessage
            defaultMessage="Create Account"
            id="5F+Kf1"
            description="Text on create account button"
          />
        </PrimaryButton>
      </Flex>
      {!isMenu && (
        <Flex className={styles.links}>
          <Flex>
            <Box as="p">
              <FormattedMessage
                defaultMessage="Already have an account?"
                id="lsINSH"
                description="A text on signup page for asking if a user already has an account"
              />
            </Box>
            <Box marginLeft={2} as="span">
              <LinkComponent className={styles.link} href="/login">
                <FormattedMessage
                  defaultMessage="Log in"
                  id="h2oWmm"
                  description="A link text on signup page"
                />
              </LinkComponent>
            </Box>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default SignupForm
