import IsEmail from 'isemail'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import Checkbox from 'src/components/UI/Checkbox/Checkbox'
import Input from 'src/components/UI/Input/Input'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import PrimaryButton from 'src/components/UI/PrimaryButton/PrimaryButton'
import messages from 'src/stores/error/messages'
import useStore from 'src/stores/useStore'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import { storageKeys } from 'src/constants'

import styles from './Newsletter.module.scss'
import NewsletterPopup from '../NewsletterPopup/NewsletterPopup'

import type { Props } from './types'

const Newsletter = ({ newsletterText, isPopup }: Props) => {
  const [email, setEmail] = useState<string>('')
  const [hasConsent, setHasConsent] = useState<boolean>(false)
  const [subscribed, setSubscribed] = useState<boolean>(false)
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null)
  const [open, setOpen] = useState<boolean | null>(null)
  const { cart, error } = useStore()
  const intl = useIntl()
  const router = useRouter()
  const { locale } = router

  useEffect(() => {
    if (!isPopup) {
      return
    }
    const timer = setTimeout(() => {
      const popupClosed = localStorage.getItem(storageKeys.POPUP_CLOSED)
      if (!popupClosed) {
        setOpen(true)
      }
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  const handleChange = (e: any) => {
    const { value } = e.target
    setEmail(value)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!IsEmail.validate(email)) {
      setFeedbackMessage(
        intl.formatMessage({
          defaultMessage: '* Invalid email',
          id: '/sgQpF',
          description:
            'Text that shows when a user has entered a invalid email address when subscribing to newsletter',
        })
      )
      return
    }

    if (!hasConsent) {
      setFeedbackMessage(
        intl.formatMessage({
          defaultMessage: '* You need to consent to our policies',
          id: 'sRdozc',
          description:
            "Text that shows when a user didn't consent when subscribing to newsletter",
        })
      )
      return
    }

    try {
      const response = await cart.subscribeNewsletter({
        email,
        locale: locale === 'sv' ? 'sv' : 'en',
      })
      if (response) {
        setEmail('')
        setSubscribed(true)
        setFeedbackMessage(
          intl.formatMessage({
            defaultMessage: '* Subscription successfull',
            id: 'zK44bc',
            description:
              'Text that shows when has successfully subscribed to newsletter',
          })
        )
        if (isPopup) {
          window.localStorage.setItem(storageKeys.POPUP_CLOSED, 'true')
        }
        setTimeout(() => setOpen(false), 3000)
      }
    } catch (e: any) {
      error.warn(messages.subscribeNewsletter, e)
    }
  }

  const consentText = (
    <FormattedMessage
      defaultMessage="Yes, I consent to Emma S's <link>policies</link>"
      id="QIyEJO"
      description="Text for a newsletter consent"
      values={{
        link: (content: string) => (
          <LinkComponent href="/information/privacy-policy">
            {content}
          </LinkComponent>
        ),
      }}
    />
  )
  const emailPlaceholder = intl.formatMessage({
    defaultMessage: 'Your email',
    id: 'Txcnqi',
    description: 'Placeholder text for newsletter e-mail address input',
  })

  if (isPopup) {
    return (
      <NewsletterPopup
        open={open}
        setOpen={setOpen}
        newsletterText={newsletterText}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        hasConsent={hasConsent}
        consentText={consentText}
        setHasConsent={setHasConsent}
        feedbackMessage={feedbackMessage}
        email={email}
        emailPlaceholder={emailPlaceholder}
        subscribed={subscribed}
      />
    )
  }

  return !isPopup ? (
    <Flex
      className={styles.newsletter}
      as="form"
      id="newsletter-footer"
      aria-label={intl.formatMessage({
        defaultMessage: 'Newsletter form',
        id: 'ftlayP',
        description: 'Accessible label for newsletter form',
      })}
      width={[1, null, null, 6 / 8]}
      height="100%"
      flexDirection="column"
      paddingY={[10, null, null, 16]}
      paddingRight={[6, null, null, 16]}
      paddingLeft={[6, null, null, 0]}
      onSubmit={handleSubmit}
      justifyContent="space-between"
    >
      <Box
        marginBottom={[6]}
        width={[5 / 6, null, null, 1]}
        className={styles.newsletterTitle}
        as="p"
      >
        {newsletterText}
      </Box>
      <Box>
        <Flex
          justifyContent="space-between"
          alignItems="flex-end"
          className={styles.inputContainer}
        >
          <Box width={['60%', null, null, '70%']}>
            <Input
              aria-label={emailPlaceholder}
              name="email"
              onChange={handleChange}
              value={email}
              placeholder={emailPlaceholder}
              autoCapitalize="off"
            />
          </Box>
          <PrimaryButton className={styles.subscribeButton}>
            <FormattedMessage
              defaultMessage="Sign up"
              id="KM7sI0"
              description="Text for newsletter pop up 'Subscribe' button"
            />
          </PrimaryButton>
        </Flex>
        <Box className={styles.consent}>
          <Checkbox
            name={'newsletter-footer-consent'}
            checked={hasConsent}
            round
            onChange={() => setHasConsent(!hasConsent)}
          >
            {consentText}
          </Checkbox>
        </Box>
        {feedbackMessage && (
          <Box
            as="p"
            className={classNames(styles.feedbackMessage, {
              [styles.errorMessage]: !subscribed,
            })}
          >
            {feedbackMessage}
          </Box>
        )}
      </Box>
    </Flex>
  ) : null
}

export default Newsletter
