import { fetchCentraProducts } from './centraapi'

import type { FilterTypes } from 'src/components/ProductsContainer/ProductsContainer'
import type { AinoProduct, Maybe } from 'src/apollo/types'

export const getCentraProducts = async ({
  token,
  productIds,
  products,
  options,
  locale,
}: {
  token: string
  productIds: string[]
  products?: Maybe<AinoProduct>[]
  options?: FilterTypes
  locale?: string | undefined
}) => {
  const centraResponse: any = await fetchCentraProducts({
    token,
    productIds,
    options,
    locale,
  })
  if (centraResponse && centraResponse.products) {
    // Since centra and our api order diffs we just put in the price and stock in the server products
    let updatedProducts = null
    if (!options && products) {
      updatedProducts = products.map((product: any) => {
        const centraProduct = centraResponse.products.find(
          (cProduct: any) => cProduct.product === product.id
        )
        return {
          ...product,
          price: centraProduct ? centraProduct.price : product.price,
          showAsOnSale: centraProduct
            ? centraProduct.showAsOnSale
            : product.showAsOnSale,
          priceAsNumber: centraProduct
            ? centraProduct.priceAsNumber
            : product.priceAsNumber,
          priceBeforeDiscount: centraProduct
            ? centraProduct.priceBeforeDiscount
            : product.priceBeforeDiscount,
          priceBeforeDiscountAsNumber: centraProduct
            ? centraProduct.priceBeforeDiscountAsNumber
            : product.priceBeforeDiscountAsNumber,
          items: centraProduct ? centraProduct.items : product.items,
        }
      })
    }

    return {
      products: updatedProducts ? updatedProducts : centraResponse.products,
      filter: centraResponse.filter,
    }
  } else {
    console.error(`Category products could not be fetched`)
  }
}

export const getCentraFilterName = (filter: string, intl: any) => {
  switch (filter) {
    case 'categories':
      return intl.formatMessage({
        defaultMessage: 'Categories',
        id: 'LtyWsA',
        description: 'Title for categories in filter',
      })
    case 'collections':
      return intl.formatMessage({
        defaultMessage: 'Series',
        id: 'VoTE3e',
        description: 'Title for series in filter',
      })
    case 'ainoSkintype':
      return intl.formatMessage({
        defaultMessage: 'Skin type',
        id: '0DEo4t',
        description: 'Title for skin type in filter',
      })
    case 'ainoRoutine':
      return intl.formatMessage({
        defaultMessage: 'Routine',
        id: 'HLQu4v',
        description: 'Title for routine in filter',
      })
    case 'ainoSkinConcern':
      return intl.formatMessage({
        defaultMessage: 'Skin concern',
        id: 'YV3A6N',
        description: 'Title for skin concern in filter',
      })
    case 'ainoProductFormula':
      return intl.formatMessage({
        defaultMessage: 'Product formula',
        id: 'Efzj8g',
        description: 'Title for product formula in filter',
      })
    case 'ainoOther':
      return intl.formatMessage({
        defaultMessage: 'Other',
        id: 'uXWvk7',
        description: 'Title for other in filter',
      })
    case 'ainoFragrance':
      return intl.formatMessage({
        defaultMessage: 'Fragrance',
        id: 'QDjHj7',
        description: 'Title for fragrance in filter',
      })
    case 'ainoKeyIngredient.name':
      return intl.formatMessage({
        defaultMessage: 'Key ingredient',
        id: 'usazPS',
        description: 'Title for key ingredient in filter',
      })
    default:
      return filter
  }
}
