/* eslint-disable formatjs/enforce-description */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  add: {
    defaultMessage: 'Add',
    id: '2/2yg+',
  },
  addGiftCardOrDiscountCode: {
    defaultMessage: 'Have a gift card? Add your code',
    id: 'Tn+EOH',
  },
  addOneItem: {
    defaultMessage: 'Add 1 item',
    id: '/IDqQd',
  },
  address: {
    defaultMessage: 'Address',
    id: 'e6Ph5+',
  },
  apply: {
    defaultMessage: 'Apply',
    description: 'Used on buttons. "Apply" as in "Apply this discount code"',
    id: 'YP/BBT',
  },
  bagNotFound: {
    defaultMessage: 'Bag not found',
    id: 'u5eFMD',
  },
  billingAddress: {
    defaultMessage: 'Billing address',
    id: '6orx1c',
  },
  country: {
    defaultMessage: 'Country',
    id: 'vONi+O',
  },
  city: {
    defaultMessage: 'City',
    id: 'TE4fIS',
  },
  clickToCompleteYourPurchase: {
    defaultMessage:
      'Click to complete your purchase at the merchant. You will then be redirected back to the shop for a receipt.',
    id: 'DyT9sl',
  },
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
  confirmYourPostalCode: {
    defaultMessage: 'Confirm your postal code',
    id: 'EPxZks',
  },
  contactInformation: {
    defaultMessage: 'Contact information',
    id: 'ITdmlJ',
  },
  continueShopping: {
    defaultMessage: 'Continue shopping',
    id: 'Yywm0p',
  },
  continueToPayment: {
    defaultMessage: 'Continue to payment',
    id: '+g+0OK',
  },
  delivery: {
    defaultMessage: 'Delivery',
    id: 'drqP2L',
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'wEQDC6',
  },
  email: {
    defaultMessage: 'E-mail',
    id: 'tkwCac',
  },
  enterACity: {
    defaultMessage: 'Enter a city',
    id: 'u1JzBg',
  },
  enterAValidCountry: {
    defaultMessage: 'Enter a valid country',
    id: 'dTZ0NV',
  },
  enterAFirstName: {
    defaultMessage: 'Enter a first name',
    id: 'y6WUKS',
  },
  enterALastName: {
    defaultMessage: 'Enter a last name',
    id: 'Nau+P4',
  },
  enterAValidEMail: {
    defaultMessage: 'Enter a valid e-mail',
    id: 'u+918I',
  },
  enterAValidPhoneNumber: {
    defaultMessage: 'Enter a valid phone number',
    id: 'iydw3u',
  },
  enterAValidState: {
    defaultMessage: 'Enter a valid state',
    id: 'lpAjT+',
  },
  enterAZipPostalCode: {
    defaultMessage: 'Enter a ZIP / postal code',
    id: 'pJi/8z',
  },
  enterAnAddress: {
    defaultMessage: 'Enter an address',
    id: 'BKdjS1',
  },
  enterYourEmail: {
    defaultMessage: 'Enter your e-mail',
    id: 'Ef40UU',
  },
  extraAddress: {
    defaultMessage: 'Extra address',
    id: 'mo7y1f',
  },
  finalDetails: {
    defaultMessage: 'Final details',
    id: 'BZBGjn',
  },
  firstName: {
    defaultMessage: 'First name',
    id: 'pONqz8',
  },
  free: {
    defaultMessage: 'Free',
    description: 'Free as in "free beer"',
    id: '++2WDo',
  },
  getDeliveryOptions: {
    defaultMessage: 'Get delivery options',
    id: 'zKkpUj',
  },
  giftCardOrDiscountCode: {
    defaultMessage: 'Gift card or discount code',
    id: '83sdHI',
  },
  grandTotal: {
    defaultMessage: 'Grand total',
    id: 'BQ1ZVi',
  },
  iAccept: {
    defaultMessage: 'I accept',
    id: '+fnRDB',
  },
  pay: {
    defaultMessage: 'Pay {sumTotal}',
    description: 'Text on a button on payment page',
    id: 'Mr3OD6',
  },
  invalidCode: {
    defaultMessage: 'Invalid code',
    id: 'x2R8CB',
  },
  lastName: {
    defaultMessage: 'Last name',
    id: 'txUL0F',
  },
  noIllChangeMyKlarnaPostalCode: {
    defaultMessage: 'No, I’ll change my Klarna postal code',
    id: 'Pjq4oQ',
  },
  oneOrMoreProductsWereOutOfStockAndHasBeenRemoved: {
    defaultMessage:
      'Note: one or more products were out of stock and has been removed',
    id: 'Yt2aSz',
  },
  orderSummary: {
    defaultMessage: 'Order summary',
    id: 'SB//YQ',
  },
  payment: {
    defaultMessage: 'Payment',
    id: 'NmK6zy',
  },
  paymentFailed: {
    defaultMessage: 'Payment failed',
    id: 'qV+TFm',
  },
  phoneNumber: {
    defaultMessage: 'Phone number',
    id: 'jdJhOL',
  },
  pleaseEnterAtLeastTwoLetters: {
    defaultMessage: 'You must enter at least 2 letters',
    id: 'BH5ojw',
  },
  pleaseEnterValidEmail: {
    defaultMessage: 'Please enter a valid email',
    id: 'prBAWB',
  },
  pleaseTryAgainOrUseADifferentPaymentMethod: {
    defaultMessage: 'Please try again or use a different payment method.',
    id: 'FrntLr',
  },
  pleaseUseLatinCharacters: {
    defaultMessage: 'Please use latin characters',
    id: 'iDt6Ke',
  },
  postalCode: {
    defaultMessage: 'Postal code',
    id: '3EnruA',
  },
  postalCodeCountry: {
    defaultMessage: 'Postal code & country',
    id: 'n6gx6i',
  },
  productImage: {
    defaultMessage: 'Product image',
    id: 'PXXu91',
  },
  reloadPage: {
    defaultMessage: 'Reload page',
    id: 'RtfJfe',
  },
  remove: {
    defaultMessage: 'Remove',
    id: 'G/yZLu',
  },
  removeOneItem: {
    defaultMessage: 'Remove 1 item',
    id: 'BIh/xX',
  },
  returnToShop: {
    defaultMessage: 'Return to shop',
    id: 'lGALwv',
  },
  saveBillingAddress: {
    defaultMessage: 'Save billing address',
    id: 'Ebs/HB',
  },
  shipping: {
    defaultMessage: 'Shipping',
    id: 'PRlD0A',
  },
  shippingAddress: {
    defaultMessage: 'Shipping address',
    id: 'ZpVtCa',
  },
  shippingMethod: {
    defaultMessage: 'Shipping method',
    id: '4RD+CZ',
  },
  shippingTo: {
    defaultMessage: 'Shipping to',
    id: 'p7jx1x',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong.',
    id: 'iuY8kx',
  },
  sorryNoDeliveryOptionsAvailable: {
    defaultMessage: 'Sorry! No delivery options available.',
    id: 'tRAi05',
  },
  sorryThereWasAnError: {
    defaultMessage: 'Sorry, there was an error',
    id: 'oZrBEC',
  },
  state: {
    defaultMessage: 'State',
    id: 'ku+mDU',
  },
  step: {
    defaultMessage: 'Step',
    description: 'As in "Step 1 of 3"',
    id: '0fNjs2',
  },
  subtotal: {
    defaultMessage: 'Subtotal',
    id: 'L8seEc',
  },
  taxDeducted: {
    defaultMessage: 'Tax deducted',
    id: 'W8HloX',
  },
  ok: {
    defaultMessage: 'OK',
    id: 'kAEQyV',
  },
  theGeneralTermsConditionsAndPrivacyPolicy: {
    defaultMessage: 'the General Terms & Conditions and Privacy Policy',
    id: 'WQd+Ku',
  },
  thePostalCodeYouHaveProvidedDoesNotMatchKlarna: {
    defaultMessage:
      'The postal code you have provided to Klarna does not match the one that you entered when choosing delivery.',
    id: 'YIYA3j',
  },
  thisMightAffectYourDeliveryOption: {
    defaultMessage: 'This might affect your delivery option.',
    id: '0TL2pC',
  },
  tokenNotFound: {
    defaultMessage: 'Token not found',
    id: 'mlIDj8',
  },
  unknownPaymentResponse: {
    defaultMessage: 'Unknown payment response',
    id: 'EJFk7C',
  },
  useShippingAddressAsBillingAddress: {
    defaultMessage: 'Use shipping address as billing address',
    id: '2htJqw',
  },
  voucher: {
    defaultMessage: 'Voucher',
    id: '9BvYb9',
  },
  wouldYouLikeDeliveryToYourKlarnaPostalCodeInstead: {
    defaultMessage:
      'Would you like to choose delivery again using your Klarna postal code instead?',
    id: 'KIa5qt',
  },
  yesIWantToChooseDeliveryMethodAgain: {
    defaultMessage: 'Yes, I want to choose delivery method again',
    id: 'vZezOC',
  },
  youMustAccept: {
    defaultMessage: 'You must accept',
    id: 'bMPn33',
  },
  yourDetails: {
    defaultMessage: 'Your details',
    id: 'HmykEw',
  },
  totalDiscount: {
    defaultMessage: 'Total discount',
    id: '7IuXwb',
  },
})

export default messages
