import { useState } from 'react'
import { motion } from 'framer-motion'

import { Box, Flex } from 'src/components/UI/Grid/Grid'
import { PlusIcon, ChevronDown } from 'src/components/UI/Icons/Icons'

import styles from './FAQSubject.module.scss'

import type {
  ContentfulFaqQuestion,
  ContentfulFaqSubject,
  Maybe,
} from 'src/apollo/types'

const tabVariant = {
  active: {
    height: '100%',
    transition: {
      type: 'tween',
      duration: 0.2,
    },
  },
  inactive: {
    height: '0',
    transition: {
      type: 'tween',
      duration: 0.2,
    },
  },
}

const FAQSubject = ({
  subject,
  small,
}: {
  subject: ContentfulFaqSubject
  small?: boolean
}) => {
  const [open, setOpen] = useState(false)
  const { title, questions } = subject

  return (
    <Flex
      className={
        small ? [styles.wrapper, styles.small].join(' ') : styles.wrapper
      }
    >
      <Flex
        onClick={() => {
          setOpen(!open)
        }}
        alignItems="center"
        className={
          open ? [styles.button, styles.active].join(' ') : styles.button
        }
        as="button"
        width={1}
        paddingX={[5, null, null, 1]}
        justifyContent="space-between"
        aria-pressed={open}
      >
        <Flex
          marginTop={[0, null, null, 1]}
          justifyContent="space-between"
          alignItems="center"
          width={1}
        >
          {title}
          <ChevronDown width={16} height={16} />
        </Flex>
      </Flex>
      <motion.div
        className={styles.questions}
        animate={open ? 'active' : 'inactive'}
        initial="inactive"
        variants={tabVariant}
      >
        {questions &&
          questions.length > 0 &&
          questions.map((question: Maybe<ContentfulFaqQuestion>) =>
            question ? (
              <Flex
                paddingX={[5, null, null, 1]}
                marginBottom={[small ? 6 : 8]}
                flexDirection="column"
                key={question.id}
              >
                <Box marginBottom={1} className={styles.question} as="p">
                  {question.title}
                </Box>
                <Box as="p" className={styles.answer}>
                  {question.answer && question.answer}
                </Box>
              </Flex>
            ) : null
          )}
      </motion.div>
    </Flex>
  )
}

export default FAQSubject
