import { Fragment, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { motion, AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import PrimaryButton from 'src/components/UI/PrimaryButton/PrimaryButton'
import Spinner from 'src/components/UI/Spinner/Spinner'
import SlideOver from 'src/components/SlideOver/SlideOver'
import ProductsContainer from 'src/components/ProductsContainer/ProductsContainer'
import useStore from 'src/stores/useStore'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import { FreightIcon, PlusIcon } from 'src/components/UI/Icons/Icons'
import { useCentraProducts } from 'src/hooks/useCentraProducts'
import messages from 'src/checkout/messages'

import UpSaleItem from './UpSaleItem/UpSaleItem'
import styles from './CentraCart.module.scss'

import type { AinoProduct, FreeFreightItem, Maybe } from 'src/apollo/types'

const round = (value: any, exp: number) => {
  if (typeof exp === 'undefined' || +exp === 0) return Math.round(value)

  value = +value
  exp = +exp

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN

  // Shift
  value = value.toString().split('e')
  value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] + exp : exp)))

  // Shift back
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp))
}

const ItemButton = ({ onClick, icon, className, ariaLabel }: any) => {
  const [pending, setPending] = useState(false)

  const handleClick = () => {
    setPending(true)
    onClick()
    setTimeout(() => {
      setPending(false)
    }, 750)
  }
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      className={pending ? [className, styles.pending].join(' ') : className}
      as="button"
      onClick={handleClick}
      aria-label={ariaLabel}
    >
      {icon}
      {pending && (
        <svg
          width={24}
          className={styles.spinner}
          height={24}
          viewBox="0 0 50 50"
        >
          <circle
            className="spinnerCircle"
            cx="25"
            cy="25"
            r="20"
            stroke="#000"
            strokeDasharray={'125'}
            // strokeDashoffset={'100'}
            fill="transparent"
            strokeWidth="1"
          ></circle>
        </svg>
      )}
    </Flex>
  )
}

const InnerCart = observer(
  ({
    freeFreight,
    upsellProducts,
  }: {
    upsellProducts: Maybe<Maybe<AinoProduct>[]> | undefined
    freeFreight: Maybe<Maybe<FreeFreightItem>[]> | undefined
  }): JSX.Element => {
    const intl = useIntl()
    const router = useRouter()
    const { cart, ui } = useStore()

    const locale = router.locale
    const { centraProducts } = useCentraProducts({
      products: upsellProducts,
      cart: cart,
      locale: locale,
    })
    const country = cart.country
    const [checkoutLoading, setCheckoutLoading] = useState(false)

    const freeFreightItem = freeFreight?.find(
      (item: Maybe<FreeFreightItem>) =>
        item && country && item.key === country.currency
    )
    const leftToFreeFreight: any =
      freeFreightItem && freeFreightItem.value && cart && cart.itemsTotalPrice
        ? parseInt(freeFreightItem.value) - cart.itemsTotalPrice
        : freeFreightItem?.value
        ? freeFreightItem.value
        : false
    const isFreeFreight = leftToFreeFreight < 1
    return (
      <Fragment>
        <SlideOver
          active={ui.activeMenu === 'cart'}
          onCloseClick={() => {
            ui.setActiveMenu('')
          }}
          className={
            ui.activeMenu === 'cart'
              ? [styles.wrapper, styles.active].join(' ')
              : styles.wrapper
          }
        >
          <Fragment>
            <Flex
              style={
                cart.pending
                  ? { opacity: 1, position: 'relative' }
                  : { opacity: 1, position: 'relative' }
              }
              flexDirection="column"
              width={1}
              className={classNames(styles.products, {
                [styles.noProducts]: cart.count === 0,
              })}
            >
              <Flex
                paddingTop={[6, null, null, 10]}
                width={1}
                paddingX={[5, null, null, 10]}
                marginBottom={[0, null, null, 2]}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box as="h3" className={styles.title}>
                  <FormattedMessage
                    defaultMessage="Your Cart"
                    id="Lj/IM7"
                    description="Title for a cart page, showing the contents of a shopping cart"
                  />
                </Box>
                <Box
                  onClick={() => {
                    ui.setActiveMenu('')
                  }}
                  className={styles.closeIcon}
                  as="button"
                  aria-label={intl.formatMessage(messages.close)}
                >
                  <PlusIcon />
                </Box>
              </Flex>

              <AnimatePresence>
                {cart.items.map((cartItem: any) => {
                  if (!cartItem) {
                    return null
                  }

                  const isSampleProduct =
                    !!cartItem.product?.ainoSampleProduct_boolean

                  const {
                    line,
                    item,
                    product,
                    totalPrice,
                    anyDiscount,
                    totalPriceBeforeDiscount,
                  } = cartItem
                  let { quantity } = cartItem

                  if (!product) {
                    return null
                  }

                  const { media, name, variantName, uri } = product

                  if (typeof quantity === 'string') {
                    quantity = parseInt(quantity)
                  }

                  const imageSrc =
                    media && media['800'] ? media['800'][0] : null

                  return (
                    <div key={item} className={styles.itemWrapper}>
                      <motion.div
                        key={item}
                        className={styles.item}
                        initial={{
                          opacity: 0,
                          height: 0,
                        }}
                        animate={{
                          opacity: 1,
                          height: '100%',
                          transition: {
                            height: {
                              duration: 0,
                            },
                            opacity: {
                              duration: 0.7,
                            },
                          },
                        }}
                        exit={{
                          opacity: 0,
                          height: 0,
                          transition: {
                            opacity: {
                              duration: 0.15,
                            },
                            height: {
                              duration: 0.3,
                            },
                          },
                        }}
                      >
                        <Flex
                          width={1}
                          paddingY={5}
                          className={styles.product}
                          key={line}
                          paddingX={[5, null, null, 10]}
                        >
                          <LinkComponent
                            onClick={() => {
                              ui.setActiveMenu('')
                            }}
                            href={`/product/${uri}`}
                            className={styles.imageWrapper}
                            ariaLabel={name}
                          >
                            {imageSrc && <Image src={imageSrc} alt={name} />}
                          </LinkComponent>
                          <Flex
                            width={1}
                            paddingX={4}
                            style={{ position: 'relative' }}
                            flexDirection="column"
                            height="100%"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Flex flexDirection="column">
                              <Box
                                marginBottom={'2px'}
                                className={styles.name}
                                as="span"
                              >
                                {name}
                              </Box>
                              <Box
                                marginBottom={2}
                                className={styles.variantName}
                                as="span"
                              >
                                {variantName}
                              </Box>
                            </Flex>
                            <Flex className={styles.quantityWrapper}>
                              <ItemButton
                                onClick={() => {
                                  cart.updateQuantity(
                                    line,
                                    (parseInt(quantity) - 1).toString()
                                  )
                                }}
                                className={styles.minus}
                                icon={
                                  <svg
                                    width="6"
                                    height="2"
                                    viewBox="0 0 8 2"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M0 1L8 1" stroke="#202020" />
                                  </svg>
                                }
                                ariaLabel={intl.formatMessage(
                                  messages.removeOneItem
                                )}
                              />

                              <Flex
                                justifyContent="center"
                                alignItems="center"
                                className={styles.quantity}
                              >
                                {quantity}
                              </Flex>
                              <ItemButton
                                onClick={() => {
                                  if (isSampleProduct) {
                                    return
                                  }
                                  cart.updateQuantity(
                                    line,
                                    (parseInt(quantity) + 1).toString()
                                  )
                                }}
                                className={[
                                  styles.plus,
                                  isSampleProduct ? styles.inactive : '',
                                ].join(' ')}
                                icon={
                                  <svg
                                    width="6"
                                    height="6"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M4 0L4 8" stroke="#202020" />
                                    <path d="M0 4L8 4" stroke="#202020" />
                                  </svg>
                                }
                                ariaLabel={intl.formatMessage(
                                  messages.addOneItem
                                )}
                              />
                            </Flex>
                          </Flex>
                          <Flex
                            height="100%"
                            justifyContent="space-between"
                            flexShrink={0}
                            flexDirection="column"
                            alignItems="flex-end"
                          >
                            <Flex
                              flexDirection={'column'}
                              className={styles.price}
                            >
                              {anyDiscount && (
                                <Box
                                  marginBottom={1}
                                  className={styles.old}
                                  as="span"
                                >
                                  {totalPriceBeforeDiscount}
                                </Box>
                              )}
                              <Box
                                className={anyDiscount ? styles.sale : ''}
                                as="span"
                              >
                                {totalPrice}
                              </Box>
                            </Flex>

                            <Flex
                              onClick={() => {
                                cart.removeItem(line)
                              }}
                              className={styles.remove}
                              as="button"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <FormattedMessage
                                defaultMessage="Remove"
                                id="pJ5lCP"
                                description="Title for a button that removes an object from the cart"
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                      </motion.div>
                    </div>
                  )
                })}
                {cart && cart.items && cart.items.length > 0 && (
                  <motion.div
                    className={[styles.item, styles.upsaleWrapper].join(' ')}
                    key={'upsell'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <Flex flexDirection="column" paddingBottom={9} flex={1}>
                      <Box
                        paddingX={[5, null, null, 10]}
                        as="span"
                        marginY={5}
                        className={styles.upsaleTitle}
                      >
                        <FormattedMessage
                          defaultMessage="You might also like"
                          id="atFCXY"
                          description="Upsale title"
                        />
                      </Box>
                      <Flex
                        flexShrink={0}
                        paddingX={[3, null, null, 6]}
                        className={styles.upsale}
                        flexWrap="wrap"
                        width={1}
                      >
                        <ProductsContainer
                          products={upsellProducts}
                          listOnly={true}
                          renderResult={({ products }) =>
                            products &&
                            products.map(
                              (product: Maybe<AinoProduct>, index: number) => {
                                const centraProduct: any =
                                  centraProducts &&
                                  centraProducts.find(
                                    (centraProduct) =>
                                      centraProduct.id === product?.id
                                  )
                                const isInCart = cart.items.find(
                                  (item: any) => {
                                    return (
                                      product &&
                                      item &&
                                      item.product &&
                                      item.product.product === product.id
                                    )
                                  }
                                )
                                return product && !isInCart ? (
                                  <Box
                                    className={
                                      index === 2
                                        ? 'hideMobile hideDesktop'
                                        : styles.upsaleItem
                                    }
                                    paddingX={[2, null, null, 4]}
                                    key={product.id}
                                    width={[1 / 2, null, null, 1 / 2]}
                                  >
                                    <UpSaleItem
                                      product={product}
                                      centraProduct={centraProduct}
                                    />
                                  </Box>
                                ) : null
                              }
                            )
                          }
                        />
                      </Flex>
                    </Flex>
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {!(cart && cart.items && cart.items.length > 0) && (
                  <Flex
                    paddingX={[10]}
                    // marginTop="auto"
                    textAlign="center"
                    width={1}
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <motion.span
                      initial={{ opacity: 0, height: 0 }}
                      animate={{
                        opacity: 1,
                        height: 'auto',
                        transition: {
                          delay: 0.5,
                        },
                      }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ height: { delay: 0.2 } }}
                      className={styles.emptyCart}
                    >
                      <FormattedMessage
                        defaultMessage="Your cart is empty"
                        id="PRHuv+"
                        description="Text shown on cart page when the customer has no products in their cart"
                      />
                    </motion.span>
                  </Flex>
                )}
              </AnimatePresence>
            </Flex>
          </Fragment>
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            // paddingX={[5, null, null, 10]}
            className={
              ui.activeMenu === 'cart'
                ? [styles.ctaWrapper, styles.active].join(' ')
                : styles.ctaWrapper
            }
          >
            <Flex
              flexDirection="column"
              className={styles.totals}
              paddingX={[5, null, null, 10]}
              paddingTop={5}
            >
              {(isFreeFreight || leftToFreeFreight > 0) && (
                <Flex
                  marginBottom={[3, null, null, 5]}
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className={styles.freightButton}
                >
                  <Flex marginRight={2}>
                    <FreightIcon />
                  </Flex>
                  {isFreeFreight ? (
                    <FormattedMessage
                      defaultMessage="This order ships for free!"
                      id="f7hFIm"
                      description="Text when free shipping is reached"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Only {value} {currency} left to free shipping!"
                      id="Ud58uG"
                      description="Total left to free sheeping text"
                      values={{
                        value: leftToFreeFreight && round(leftToFreeFreight, 2),
                        currency: country?.currency,
                      }}
                    />
                  )}
                </Flex>
              )}
              <Flex
                marginBottom={[
                  1,
                  null,
                  null,
                  cart?.items?.length === 0 ? 0 : 2,
                ]}
                className={styles.summary}
                width={1}
                justifyContent="space-between"
              >
                {cart?.items?.length !== 0 && (
                  <>
                    <Box as="span">
                      <FormattedMessage
                        defaultMessage="Shipping"
                        id="0+qm1D"
                        description="Title for the 'Shipping' section of a shopping cart page"
                      />
                    </Box>
                    <Box as="span">{cart.totalShipping}</Box>
                  </>
                )}
              </Flex>
              {cart?.cart?.selection?.discounts?.anyDiscount ? (
                <Flex
                  marginBottom={[1, null, null, 2]}
                  className={styles.summary}
                  width={1}
                  justifyContent="space-between"
                >
                  <Box className={styles.total} as="span">
                    <FormattedMessage
                      defaultMessage="Total Discount"
                      id="EjVFwM"
                      description="Title for the 'Total discount' section of a shopping cart page"
                    />
                  </Box>
                  <Box as="span">
                    {cart?.cart?.selection?.discounts?.discount}
                  </Box>
                </Flex>
              ) : null}
              <Flex
                marginBottom={[0, null, null, 5]}
                className={styles.summary}
                width={1}
                justifyContent="space-between"
              >
                <Box className={styles.total} as="span">
                  <FormattedMessage
                    defaultMessage="Total"
                    id="0ZBog3"
                    description="Title for the 'Totals' section of a shopping cart page"
                  />
                </Box>
                <Box as="span">{cart.totalPrice}</Box>
              </Flex>
            </Flex>
            <Box paddingX={[5, null, null, 10]} paddingTop={[3, null, null, 5]}>
              {cart.items.length > 0 ? (
                <LinkComponent
                  onClick={() => {
                    setCheckoutLoading(true)
                  }}
                  href="/checkout"
                >
                  <PrimaryButton className={styles.cta}>
                    {checkoutLoading ? (
                      <span>
                        <Spinner
                          className={styles.buttonSpinner}
                          color="#fff"
                          width={25}
                          height={25}
                        />
                        {/* <FormattedMessage
                          defaultMessage="Loading..."
                          id="KmuT+3"
                          description="Loading"
                        /> */}
                      </span>
                    ) : (
                      <span>
                        <FormattedMessage
                          defaultMessage="Go to checkout"
                          id="TTm4Nj"
                          description="Link to checkout page from a shopping cart page"
                        />
                      </span>
                    )}
                  </PrimaryButton>
                </LinkComponent>
              ) : (
                <PrimaryButton
                  className={styles.cta}
                  onClick={() => {
                    ui.setActiveMenu('')
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Back to shop"
                    id="fWAlNZ"
                    description="Link back to shop from a shopping cart page"
                  />
                </PrimaryButton>
              )}
            </Box>
          </Flex>
        </SlideOver>
      </Fragment>
    )
  }
)

const CentraCart = ({
  freeFreight,
  upsellProducts,
}: {
  freeFreight: Maybe<Maybe<FreeFreightItem>[]> | undefined
  upsellProducts: Maybe<Maybe<AinoProduct>[]> | undefined
}): JSX.Element | null => {
  const [showCart, setShowCart] = useState(false)

  useEffect(() => {
    setShowCart(true)
  }, [])

  if (!showCart) {
    return null
  }

  return <InnerCart upsellProducts={upsellProducts} freeFreight={freeFreight} />
}

export default CentraCart
