import { Box } from 'src/components/UI/Grid/Grid'

import styles from './PrimaryButton.module.scss'

const PrimaryButton = ({ children, inverted, className, ...rest }: any) => (
  <Box
    as="button"
    {...rest}
    className={[styles.button, className, inverted ? styles.inverted : ''].join(
      ' '
    )}
  >
    {children}
  </Box>
)

export default PrimaryButton
