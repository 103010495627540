import { motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'

type Props = {
  active: boolean
  slideInFromDirection?: 'left' | 'right'
  children: any
  className?: string
  onClick?: (e: React.MouseEvent) => any
  onCloseClick: (e?: React.MouseEvent) => any
}

import styles from './SlideOver.module.scss'

const SlideOver = ({
  active,
  slideInFromDirection,
  className,
  children,
  onClick,
  onCloseClick,
}: Props) => {
  const intl = useIntl()

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.keyCode === 27) {
        onCloseClick && onCloseClick()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    if (wrapperRef && active) {
      if (wrapperRef.current) {
        document.body.classList.add('no-scroll')
      }
      setTimeout(() => {
        const buttonFound = wrapperRef.current?.getElementsByTagName('button')
        if (buttonFound && buttonFound[0]) {
          buttonFound[0].focus()
        }
      }, 100)
    }

    return () => {
      document.body.classList.remove('no-scroll')
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [active])

  return (
    <>
      <motion.div
        onClick={onClick}
        ref={wrapperRef}
        animate={
          active
            ? {
                transform: 'translateX(0%)',
                display: 'flex',
              }
            : {
                transform:
                  slideInFromDirection == 'left'
                    ? 'translateX(-150%)'
                    : 'translateX(150%)',
                transitionEnd: { display: 'none' },
              }
        }
        initial={{
          transform:
            slideInFromDirection == 'left'
              ? 'translateX(-150%)'
              : 'translateX(150%)',
          display: 'none',
        }}
        transition={{
          ease: [0.19, 1, 0.22, 1],
          duration: 0.6,
          delay: 0,
        }}
        role="dialog"
        aria-hidden={!active}
        className={className}
      >
        {children}
      </motion.div>
      <button
        aria-hidden={true}
        aria-label={intl.formatMessage({
          defaultMessage: 'Close Slideover',
          id: '6MDh/k',
          description: 'Accessibility label for closing a SlideOver',
        })}
        onClick={onCloseClick}
        tabIndex={active ? 0 : -1}
        className={active ? [styles.bg, styles.active].join(' ') : styles.bg}
      />
    </>
  )
}

export default SlideOver
