import { observable, action, makeObservable } from 'mobx'
import { MessageDescriptor } from 'react-intl'

import { Warning, Info, CustomError, Fatal } from './errors'

class ErrorStore {
  message: null | MessageDescriptor = null

  constructor() {
    makeObservable(this, {
      message: observable,
      setMessage: action.bound,
      clearMessage: action.bound,
      log: action.bound,
      warn: action.bound,
      report: action.bound,
      throwClassComponentError: action.bound,
    })
  }

  setMessage(message: MessageDescriptor): void {
    this.message = message
  }

  clearMessage(): void {
    this.message = null
  }

  log(message: MessageDescriptor, error?: Error): Info {
    const infoError = new Info(
      (message.defaultMessage as string) || (error ? error.message : 'Info')
    )
    this.report(infoError)
    return infoError
  }

  warn(message: MessageDescriptor, error?: Error): Warning {
    this.setMessage(message)
    const warningError = new Warning(
      (message.defaultMessage as string) || (error ? error.message : 'Warning')
    )
    this.report(warningError)
    return warningError
  }

  report(error: CustomError): void {
    console.log('TOTO: send to sentry', error.originalError || error)
  }

  throwClassComponentError(message: string, component: any, error?: Error) {
    const fatalError = new Fatal(message, error)
    this.report(fatalError)
    component.setState(() => {
      throw fatalError
    })
  }
}

export default ErrorStore
