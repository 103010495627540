/* eslint-disable formatjs/enforce-description */
import { defineMessages } from 'react-intl'

export default defineMessages({
  addToCart: {
    id: 'fJuQb8',
    defaultMessage:
      'Something went wrong when adding the product to your cart.',
  },
  subscribeNewsletter: {
    id: 'MVZ7QH',
    defaultMessage: 'Something went wrong when subscribing to newsletter.',
  },
  setLanguage: {
    id: 'P+571v',
    defaultMessage: 'Response error when setting centra language',
  },
  fetchUser: {
    id: 'pbN109',
    defaultMessage:
      'Something went wrong when trying to get your information. Please try again.',
  },
  loginUser: {
    id: 'raIDdb',
    defaultMessage: 'Something went wrong when logging in. Please try again.',
  },
  logoutUser: {
    id: 'kQ2iLF',
    defaultMessage: 'Something went wrong when logging out. Please try again.',
  },
  registerUser: {
    id: 'MOAEPC',
    defaultMessage: 'Something went wrong when signing up. Please try again.',
  },
  emailAlreadyRegistered: {
    id: 'UET+GP',
    defaultMessage: 'The email address is already registered.',
  },
  sendResetPasswordLink: {
    id: 'FkNR+9',
    defaultMessage: "Couldn't send reset password. Please try again soon.",
  },
  resetPassword: {
    id: 'lYYvyU',
    defaultMessage: "Couldn't reset password. Please try again soon.",
  },
})
