import { FormattedMessage } from 'react-intl'

import { Box } from 'src/components/UI/Grid/Grid'

import styles from './Spinner.module.scss'

const Spinner = ({ color = '#666', className, ...rest }: any) => (
  <Box
    {...rest}
    className={[styles.wrapper, className].join(' ')}
    aria-busy="true"
    aria-live="polite"
  >
    <span className="visuallyHidden">
      <FormattedMessage
        defaultMessage="Loading..."
        id="KmuT+3"
        description="Loading"
      />
    </span>
    <svg className={styles.circular} viewBox="25 25 50 50">
      <circle
        className={styles.path}
        stroke={color}
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  </Box>
)

export default Spinner
