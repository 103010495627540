import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import useStore from 'src/stores/useStore'
import { CartIcon } from 'src/components/UI/Icons/Icons'
import { Flex } from 'src/components/UI/Grid/Grid'

import styles from './CartButton.module.scss'

const CartButton = () => {
  const [showCart, setShowCart] = useState(false)

  useEffect(() => {
    setShowCart(true)
  }, [])

  if (!showCart) {
    return <Button />
  } else {
    return <Button loaded={showCart} />
  }
}

const Button = observer(({ loaded }: { loaded?: boolean }) => {
  const intl = useIntl()
  const { cart, ui } = useStore()

  return (
    <Flex
      flexShrink={0}
      height="100%"
      as="button"
      justifyContent="center"
      alignItems="center"
      onClick={() => {
        ui?.setActiveMenu('cart')
      }}
      className={styles.button}
      aria-label={intl.formatMessage({
        defaultMessage: 'Show cart',
        id: 'BT2fqm',
        description: 'Label for button that opens the cart window',
      })}
    >
      <CartIcon />
      {loaded && cart.count > 0 && (
        <Flex
          alignItems="center"
          justifyContent="center"
          className={styles.favCount}
        >
          {cart.count}
        </Flex>
      )}
    </Flex>
  )
})

export default CartButton
