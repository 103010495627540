interface Props {
  priceAsNumber: number
  discountAsNumber: number
}

const getPriceByAndDiscount = (product: any): Props => {
  let priceAsNumber = 0
  let discountAsNumber = 0
  if (product?.priceAsNumber) {
    priceAsNumber = product.priceAsNumber
    if (product.priceBeforeDiscountAsNumber) {
      discountAsNumber =
        product.priceBeforeDiscountAsNumber - product.priceAsNumber
    }
  }

  return { priceAsNumber, discountAsNumber }
}

export default getPriceByAndDiscount
