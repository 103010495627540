import { FormattedMessage } from 'react-intl'
import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import useStore from 'src/stores/useStore'
import { Box, Flex } from 'src/components/UI/Grid/Grid'
import { ChevronDown } from 'src/components/UI/Icons/Icons'

import styles from './MobileNav.module.scss'
import { Props } from './types'

const tabVariant = {
  active: {
    height: '100%',
    transition: {
      type: 'tween',
      duration: 0.2,
    },
  },
  inactive: {
    height: '0',
    transition: {
      type: 'tween',
      duration: 0.2,
    },
  },
}

const MobileNav = ({ nav }: Props) => {
  const router = useRouter()
  const { ui, cart } = useStore()
  const { locale } = router
  const [activeItem, setActiveItem] = useState(0)
  const [customerCurrency, setCustomerCurrency] = useState<string>('')

  useEffect(() => {
    if (
      cart &&
      cart.country &&
      cart.country.currency &&
      cart.country.currency !== customerCurrency
    ) {
      setCustomerCurrency(cart.country.currency)
    }
  }, [cart, cart?.country?.currency, customerCurrency])

  return (
    <Flex
      as="nav"
      flexDirection="column"
      className={
        ui.activeMenu === 'nav'
          ? [styles.wrapper, styles.active].join(' ')
          : styles.wrapper
      }
    >
      {nav &&
        nav.length > 0 &&
        nav.map((navItem: any, index: number) =>
          navItem.subMenus && navItem.subMenus.length > 0 ? (
            <Flex
              key={`${index}_${navItem.title}`}
              className={styles.itemWrapper}
            >
              <Flex
                onClick={() => {
                  setActiveItem(activeItem === index ? -1 : index)
                }}
                alignItems="center"
                className={
                  activeItem === index
                    ? [styles.button, styles.active].join(' ')
                    : styles.button
                }
                aria-pressed={activeItem === index}
                as="button"
                width={1}
                paddingX={[5, null, null, 1]}
                justifyContent="space-between"
              >
                {navItem.title}
                <ChevronDown />
              </Flex>
              <motion.div
                className={styles.links}
                animate={activeItem === index ? 'active' : 'inactive'}
                variants={tabVariant}
              >
                <Flex width={1}>
                  {navItem.subMenus.map((subItem: any, index: number) =>
                    subItem ? (
                      <Flex
                        paddingX={5}
                        width={1 / 2}
                        paddingY={8}
                        flexDirection="column"
                        className={index === 1 ? styles.rightColumn : ''}
                        key={`${index}_${subItem.title}`}
                      >
                        {navItem.subMenus.length > 1 && (
                          <Box marginBottom={2} className={styles.subTitle}>
                            {subItem.title}
                          </Box>
                        )}
                        {subItem.links &&
                          subItem.links.map((link: any) => (
                            <LinkComponent
                              key={`${link.slug}_${link.title}`}
                              onClick={() => {
                                ui.setActiveMenu('')
                              }}
                              href={link.slug}
                            >
                              {link.title}
                            </LinkComponent>
                          ))}
                      </Flex>
                    ) : null
                  )}
                </Flex>
              </motion.div>
            </Flex>
          ) : (
            <LinkComponent
              className={styles.button}
              onClick={() => {
                ui.setActiveMenu('')
              }}
              href={navItem.slug}
              key={navItem.slug}
            >
              {navItem.title}
            </LinkComponent>
          )
        )}
      <Flex flexDirection="column" width={1} className={styles.subButtons}>
        <LinkComponent
          className={styles.subButton}
          onClick={() => {
            ui.setActiveMenu('')
          }}
          href="/faq"
        >
          <FormattedMessage
            defaultMessage="FAQ"
            id="jJlE3Y"
            description="FAQ link i mobile nav"
          />
        </LinkComponent>
        <LinkComponent
          className={styles.subButton}
          onClick={() => {
            ui.setActiveMenu('')
          }}
          href="/profile"
        >
          <FormattedMessage
            defaultMessage="My pages / Sign in"
            id="YAG35u"
            description="My pages / Sign in link i mobile nav"
          />
        </LinkComponent>
        {customerCurrency ? (
          <Flex
            onClick={() => {
              ui.setActiveMenu('country')
            }}
            className={styles.subButton}
          >
            {`${locale && locale.toUpperCase()} / ${customerCurrency}`}
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default observer(MobileNav)
