import Router from 'next/router'
import debounce from 'lodash.debounce'
import { FormattedMessage, useIntl } from 'react-intl'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import SearchProduct from 'src/components/SearchProduct/SearchProduct'
import useStore from 'src/stores/useStore'
import { Box, Flex } from 'src/components/UI/Grid/Grid'
import { CloseIcon, SearchIcon } from 'src/components/UI/Icons/Icons'
import { parseCentraProductToAinoProduct } from 'src/utils/parser'
import { search } from 'src/utils/centraapi'

import styles from './NavSearch.module.scss'

import type { CentraProduct } from 'src/types'
import type { Maybe, Scalars } from 'src/apollo/types'

type CentraCategory = {
  name: string
  slug: string
  count: number
}

const NavSearch = ({
  popularSearches,
  uspVisible,
}: {
  popularSearches: Maybe<Array<Maybe<Scalars['String']>>> | undefined
  uspVisible: boolean
}) => {
  const intl = useIntl()
  const { cart, ui } = useStore()
  const inputRef: any = useRef(null)

  const [pending, setPending] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [searchResults, setSearchResults] = useState<CentraProduct[] | null>(
    null
  )

  const [searchCategories, setSearchCategories]: CentraCategory[] | any[] =
    useState([])

  const searchHandler = (e: any) => {
    if (!submitted) {
      const { value } = e.target
      ui.setActiveMenu('search')
      setSearchPhrase(value)
      if (value) {
        setPending(true)
      } else {
        setPending(false)
        setSearchResults(null)
        setSearchCategories(null)
      }
    }
  }

  const submitHandler = (e: any) => {
    ui.setActiveMenu('')

    Router.push(`/search#search=${searchPhrase}`)
    if (inputRef && inputRef.current) {
      inputRef.current.blur()
    }
    setSubmitted(true)
    setTimeout(() => {
      inputRef.current.value = ''
      setSubmitted(false)
    }, 2000)
    e.stopPropagation()
    e.preventDefault()
  }

  const debouncedSearchHandler = useMemo(() => debounce(searchHandler, 300), [])

  useEffect(() => {
    async function doSearch() {
      const token = cart.token
      const searchResults: any = await search(token, searchPhrase)
      setPending(false)

      if (searchResults && searchResults.products) {
        setSearchResults(
          searchResults.products
            .filter(
              (p: CentraProduct) =>
                p.ainoSampleProduct_boolean !== '1' &&
                p.ainoGiftProduct_boolean !== '1'
            )
            .map((p: any) => parseCentraProductToAinoProduct(p))
        )
      }
      if (searchResults && searchResults.filter) {
        const categoryValues: any = Object.values(searchResults.filter).find(
          (filter: any) => filter.field === 'categories'
        )
        const categories =
          categoryValues && categoryValues.values
            ? categoryValues.values
                .map((value: any) => ({
                  count: value.count,
                  name: value.data.name[value.data.name.length - 1],
                  slug: value.data.uri,
                }))
                .slice(0, 8)
            : []

        setSearchCategories(categories)
      }
    }
    if (searchPhrase) {
      doSearch()
    }
  }, [cart.token, searchPhrase])

  useEffect(() => {
    return () => {
      debouncedSearchHandler.cancel()
    }
  }, [debouncedSearchHandler])
  return (
    <Fragment>
      <SearchIcon className={styles.searchIcon} width={16} height={16} />
      <form className={styles.form} onSubmit={submitHandler} role="search">
        <input
          ref={inputRef}
          id={'searchInput'}
          className={styles.input}
          onChange={debouncedSearchHandler}
          onSubmit={submitHandler}
          onFocus={() => {
            ui.setActiveMenu('search')
          }}
          spellCheck={false}
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="off"
          type="search"
          placeholder={intl.formatMessage({
            defaultMessage: 'Search',
            id: 'aH6kOn',
            description: 'Placeholder text for search input',
          })}
        />
        {inputRef && inputRef.current && inputRef.current.value !== '' && (
          <button
            type="reset"
            onClick={(e: any) => {
              e.preventDefault()
              e.stopPropagation()
              if (inputRef && inputRef.current) {
                inputRef.current.value = ''
                searchHandler({
                  target: {
                    value: '',
                  },
                })
                if (location && location.href.startsWith('/search')) {
                  Router.push(`/search#search=`)
                }
                inputRef.current.focus()
              }
            }}
            className={styles.resetButton}
          >
            <CloseIcon />
          </button>
        )}
      </form>
      {ui.activeMenu === 'search' && (
        <Flex
          width={1}
          aria-label={intl.formatMessage({
            defaultMessage: 'Search results and suggestions',
            id: 'GzVq34',
            description:
              'Accessible label for the whole area with search results and suggestions',
          })}
          className={
            pending
              ? [
                  styles.searchResults,
                  styles.pending,
                  uspVisible ? styles.uspVisible : '',
                ].join(' ')
              : [
                  styles.searchResults,
                  uspVisible ? styles.uspVisible : '',
                ].join(' ')
          }
        >
          {searchPhrase ? (
            searchResults && searchResults.length > 0 ? (
              <Flex width={1}>
                <Flex
                  paddingX={[5, null, null, 14]}
                  paddingY={[10]}
                  flexDirection="column"
                  width={[1, null, null, 3 / 4]}
                  className={styles.borderRight}
                >
                  <Flex marginBottom={[6]} justifyContent="space-between">
                    <h3 className={styles.title}>
                      <FormattedMessage
                        defaultMessage="Products"
                        id="B5mLDc"
                        description="Presenting a list of product search results"
                      />
                    </h3>
                    <LinkComponent
                      className={styles.viewAll}
                      onClick={() => {
                        ui.setActiveMenu('')
                      }}
                      href={`/search#search=${searchPhrase}`}
                    >
                      <FormattedMessage
                        defaultMessage="View all results"
                        id="fYgHVs"
                        description="View all search results"
                      />
                    </LinkComponent>
                  </Flex>
                  <Box className={styles.products} width={1}>
                    {searchResults.slice(0, 4).map((item: CentraProduct) => {
                      return (
                        <Box
                          marginBottom={[4, null, null, 6]}
                          width={1}
                          key={item.uri}
                        >
                          <SearchProduct
                            onClick={() => {
                              ui.setActiveMenu('')
                            }}
                            product={item}
                          />
                        </Box>
                      )
                    })}
                  </Box>
                </Flex>
                <Flex
                  paddingX={[14]}
                  paddingY={[10]}
                  flexDirection="column"
                  width={1 / 4}
                  className="hideMobile"
                >
                  <Box as="h3" marginBottom={[6]} className={styles.title}>
                    <FormattedMessage
                      defaultMessage="Categories"
                      id="yW1wfG"
                      description="Presenting a list of category search results"
                    />
                  </Box>
                  <Flex width={1} flexWrap="wrap">
                    {searchCategories &&
                      searchCategories.length > 0 &&
                      searchCategories.map((category: any) => (
                        <LinkComponent
                          key={category.slug}
                          onClick={() => {
                            ui.setActiveMenu('')
                          }}
                          className={styles.tag}
                          href={`/category/${category.slug}`}
                        >
                          {category.name}
                        </LinkComponent>
                      ))}
                  </Flex>
                </Flex>
              </Flex>
            ) : pending ? (
              <Flex
                paddingX={[5, null, null, 14]}
                paddingY={[10]}
                flexDirection="column"
                width={1}
              >
                <Box as="h3" marginBottom={6} className={styles.title}>
                  <FormattedMessage
                    defaultMessage="Popular searches"
                    id="EjAU/L"
                    description="Presenting a list of popular searches"
                  />
                </Box>
                <Flex maxWidth="400px" flexWrap="wrap">
                  {popularSearches &&
                    popularSearches.map((pop: Maybe<string>) => (
                      <LinkComponent
                        key={pop}
                        onClick={() => {
                          ui.setActiveMenu('')
                        }}
                        className={styles.tag}
                        href={`/search#search=${pop}`}
                      >
                        {pop}
                      </LinkComponent>
                    ))}
                </Flex>
              </Flex>
            ) : (
              <Flex
                alignItems="center"
                justifyContent="center"
                paddingX={[14]}
                paddingY={10}
                width={1}
                height="440px"
              >
                <Box className={styles.notFound} as="p">
                  <FormattedMessage
                    defaultMessage={`We could not find any products for "{searchPhrase}"`}
                    id="IltM/Z"
                    description="Text when no results were found in header search"
                    values={{ searchPhrase: searchPhrase }}
                  />
                </Box>
              </Flex>
            )
          ) : (
            <Flex
              paddingX={[5, null, null, 14]}
              paddingY={[10]}
              flexDirection="column"
              width={1}
            >
              <Box as="h3" marginBottom={6} className={styles.title}>
                <FormattedMessage
                  defaultMessage="Popular searches"
                  id="EjAU/L"
                  description="Presenting a list of popular searches"
                />
              </Box>
              <Flex maxWidth="400px" flexWrap="wrap">
                {popularSearches &&
                  popularSearches.map((pop: Maybe<string>) => (
                    <LinkComponent
                      key={pop}
                      onClick={() => {
                        ui.setActiveMenu('')
                      }}
                      className={styles.tag}
                      href={`/search#search=${pop}`}
                    >
                      {pop}
                    </LinkComponent>
                  ))}
              </Flex>
            </Flex>
          )}
        </Flex>
      )}
    </Fragment>
  )
}

export default observer(NavSearch)
