import { Flex } from 'src/components/UI/Grid/Grid'

import styles from './Tag.module.scss'

import type { Maybe } from 'src/apollo/types'

type Props = {
  title: string | Maybe<string> | undefined
  size?: string
  border?: boolean
}

const Tag = ({ title, size }: Props) => {
  return (
    <Flex
      className={styles.tag}
      alignItems="center"
      width="fit-content"
      height={size === 'small' ? 27 : 32}
      paddingX={size === 'small' ? 3 : 4}
      as="span"
      paddingTop="2px"
      marginBottom={2}
    >
      {title}
    </Flex>
  )
}

export default Tag
