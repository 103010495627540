import * as React from 'react'

import styles from './Checkbox.module.scss'
import { Props } from './types'

const Checkbox = ({
  name,
  children,
  checked,
  onChange,
  className,
  tabIndex,
  disabled,
  fullWidth,
  round,
  noCheckedBorder,
  required,
}: Props) => (
  <React.Fragment>
    <input
      disabled={disabled}
      onChange={onChange}
      type="checkbox"
      checked={checked}
      name={name}
      id={name}
      tabIndex={tabIndex}
      required={required}
      className={[
        styles.checkbox,
        className,
        round ? styles.round : {},
        noCheckedBorder ? styles.noCheckedBorder : {},
      ].join(' ')}
    />
    <label htmlFor={name} className={fullWidth ? styles.fullWidth : ''}>
      <span>{children}</span>
    </label>
  </React.Fragment>
)

export default Checkbox
