import * as React from 'react'
import classnames from 'classnames'
import isEqual from 'lodash.isequal'

import { Breakpoints, Props } from './types'

export const breakpoints: Breakpoints = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1023px)',
  desktop: '(min-width: 1024px)',
}

export default class Image extends React.Component<Props> {
  preloadRef: any
  imageRef: any
  observer: any = null
  visible = false

  static defaultProps = {
    sizes: {
      mobile: '100vw',
      tablet: '50vw',
      desktop: '50vw',
    },
    progressive: true,
  }

  constructor(props: Props) {
    super(props)
    if (!props.src && !props.srcSet) {
      // throw new Error('You must specify src or srcSet')
      console.log('You must specify src or srcSet on Image')
    }
    if (typeof IntersectionObserver !== 'undefined') {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: '0px 0px 500px 0px',
      })
    }
  }

  componentDidMount() {
    const { critical } = this.props
    if (critical) {
      this.show()
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { src, srcSet } = this.props
    if (!isEqual(prevProps.src, src) || !isEqual(prevProps.srcSet, srcSet)) {
      this.setSrc()
    }
  }

  observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting && this.imageRef && this.observer) {
        this.show()
        this.observer.unobserve(entry.target)
      }
    }
  }

  getClosestSrc(width: number) {
    const { srcSet, src } = this.props
    if (!srcSet) {
      return src || ''
    }
    const closest = Object.keys(srcSet)
      .map((size: string) => parseFloat(size))
      .reduce((prev: number, curr: number) =>
        Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev
      )
    return srcSet[closest.toString()]
  }

  getSrcSet() {
    const { srcSet, ratio } = this.props
    if (!srcSet) {
      return ''
    }
    const r = ratio || 1
    const addVersionFlag = () => {
      Object.keys(srcSet).forEach((csrcSet) => {
        if (process.env.STATIC_VERSION) {
          let value = srcSet[csrcSet]
          if (value.indexOf('/dyn/productimages/') !== -1) {
            value = value.replace('.jpg', `__${process.env.STATIC_VERSION}.jpg`)
          }
          srcSet[csrcSet] = value
        }
      })
    }
    addVersionFlag()
    return Object.keys(srcSet)
      .map(
        (width: string) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          `${srcSet[width]} ${Math.round(parseFloat(width) / r)}w`
      )
      .join(', ')
  }

  getSrc() {
    const { src } = this.props
    if (src) {
      return src
    }
    return this.getClosestSrc(800)
  }

  getLowRes() {
    const { lowRes } = this.props
    return lowRes || this.getClosestSrc(200)
  }

  getPlaceholder() {
    const { ratio } = this.props
    if (!ratio) {
      // Return an empty pixel
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89fkzEwAIswLkYLzKuwAAAABJRU5ErkJggg=='
    }
    const viewBox = ratio > 1 ? `0 0 1 ${ratio}` : `0 0 ${ratio} 1`
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}"><rect fill="${encodeURIComponent(
      '#FFFFFF00'
    )}" width="100%" height="100%" /></svg>`
  }

  getSizes() {
    const { sizes } = this.props
    if (typeof sizes === 'string') {
      return sizes
    } else if (typeof sizes === 'object') {
      return Object.keys(sizes)
        .map((width: string) => `${breakpoints[width]} ${sizes[width]}`)
        .join(',')
    }
    return ''
  }

  setSrc() {
    if (this.imageRef) {
      const { critical, progressive } = this.props
      if ('srcset' in this.imageRef) {
        if (critical || !progressive) {
          this.imageRef.setAttribute('srcset', this.getSrcSet())
        } else {
          const setSrcSet = () => {
            if (this.imageRef) {
              this.imageRef.onload = null
              this.imageRef.onerror = null
              this.imageRef.setAttribute('srcset', this.getSrcSet())
            }
          }
          this.imageRef.setAttribute('src', this.getLowRes())
          if (this.imageRef.complete) {
            setSrcSet()
          } else {
            this.imageRef.onload = setSrcSet
            this.imageRef.onerror = setSrcSet
          }
        }
        this.imageRef.style.opacity = 1
      } else {
        this.imageRef.setAttribute('src', this.getSrc())
        this.imageRef.style.opacity = 1
      }
    }
  }

  show() {
    if (this.visible) {
      return
    }
    this.visible = true
    this.setSrc()
  }

  render() {
    const { alt, critical, title } = this.props
    let { className } = this.props
    className = classnames(className, 'aino-image', { critical })
    return (
      <React.Fragment>
        <noscript>
          <img className={className} src={this.getSrc()} alt={alt} />
        </noscript>
        <img
          ref={(x) => {
            this.imageRef = x
            if (x && this.observer) {
              this.observer.observe(x)
            }
          }}
          className={classnames(className, 'no-js')}
          sizes={this.getSizes()}
          src={critical ? this.getLowRes() : this.getPlaceholder()}
          srcSet=""
          alt={alt}
          title={title}
        />
      </React.Fragment>
    )
  }
}
