import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { defineMessages, useIntl } from 'react-intl'

import CartButton from 'src/components/CartButton/CartButton'
import HoverLink from 'src/components/HoverLink/HoverLink'
import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import MobileNav from 'src/components/MobileNav/MobileNav'
import NavSearch from 'src/components/NavSearch/NavSearch'
import useStore from 'src/stores/useStore'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import { getContentfulSrcSet } from 'src/utils/image'
import {
  ChevronDown,
  CloseIcon,
  HeartIcon,
  LoginIcon,
  LogoIcon,
  PlusIcon,
  SearchIcon,
} from 'src/components/UI/Icons/Icons'
import { toggleBodyClass } from 'src/utils/misc'

import styles from './Header.module.scss'

import type { Link, Maybe, NavItem, UspBar } from 'src/apollo/types'

type Props = {
  navItems: Maybe<Array<Maybe<NavItem>>> | undefined
  faqRef: Maybe<Link> | undefined
  popularSearches: Maybe<Maybe<string>[]> | undefined
  uspBar: Maybe<UspBar> | undefined
}

// This way of defining messages is not the recommended way any longer,
// but we do it here to avoid duplicates.
const messages = defineMessages({
  showFavorites: {
    defaultMessage: 'Show favorites',
    id: 'HpMAVU',
    description: 'Label for button that opens the favorites window',
  },
  showLogin: {
    defaultMessage: 'Show log in window',
    id: 'SXRqOk',
    description: 'Label for button that opens the log in window',
  },
  changeLanguageAndCurrency: {
    defaultMessage: 'Change language and currency',
    id: 'Xlxr27',
    description:
      'Accessible label for button that opens language and currency change overlay',
  },
  showProfile: {
    defaultMessage: 'Show profile window',
    id: 'sJJ8wZ',
    description: 'Label for button that opens the profile window',
  },
  closeAnnouncements: {
    defaultMessage: 'Close announcements',
    id: '72pBaf',
    description: 'Label for button that closes the announcement bar',
  },
  backToMainNavigationFrom: {
    defaultMessage: 'Back to main navigation from topic',
    id: 'PRvnK2',
    description: 'Label for closing an open navigation topic',
  },
  toggleSearch: {
    defaultMessage: 'Toggle search window',
    id: 'qDq2vI',
    description: 'Label for button that opens or closes the search window',
  },
})

const Header = ({ navItems, faqRef, popularSearches, uspBar }: Props) => {
  const intl = useIntl()
  const router = useRouter()
  const { cart, ui } = useStore()
  const [activeNav, setActiveNav] = useState(-1)
  const [showUsp, setShowUsp] = useState(true)
  const { locale } = router
  const firstNavItem = useRef<HTMLButtonElement>(null)
  const desktopNavItems = useRef<HTMLDivElement>(null)
  const lastActiveNav = useRef(activeNav)

  useEffect(() => {
    if (!uspBar) {
      document.body.classList.add('no-uspbar')
    }
    if (uspBar && !uspBar.text) {
      document.body.classList.add('no-uspbar')
    }

    if (activeNav == -1) {
      // closing nav, focus on correct button
      const buttonFound =
        desktopNavItems.current?.getElementsByTagName('button')

      if (buttonFound && buttonFound[lastActiveNav.current]) {
        buttonFound[lastActiveNav.current].focus()
      }
    } else {
      // opening, focus on close button
      setTimeout(() => {
        if (firstNavItem && firstNavItem.current) {
          firstNavItem.current.focus()
        }
      }, 100)
      lastActiveNav.current = activeNav
    }
  }, [uspBar, activeNav])

  return (
    <div>
      <Box
        className={
          (activeNav > -1 && ui.activeMenu === 'nav') ||
          ui.activeMenu === 'search'
            ? [styles.overlay, styles.active].join(' ')
            : styles.overlay
        }
        aria-hidden={true}
        onClick={() => {
          setActiveNav(-1)
          ui.setActiveMenu('')
        }}
      />
      <Flex
        className={
          ui.activeMenu !== ''
            ? [styles.header, styles.active].join(' ')
            : styles.header
        }
        as="header"
        flexDirection="column"
      >
        {uspBar && uspBar.text && showUsp && (
          <React.Fragment>
            <LinkComponent
              href={
                uspBar && uspBar.link && uspBar.link.slug
                  ? uspBar.link.slug
                  : '/'
              }
              role="complementary"
              className={styles.uspBar}
            >
              <Box as="p">{uspBar.text}</Box>
              <button
                onClick={(e: any) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setShowUsp(false)
                  document.body.classList.add('no-uspbar')
                }}
                className={styles.closeUSP}
                aria-label={intl.formatMessage(messages.closeAnnouncements)}
              >
                <CloseIcon color="#ffffff" width={10} height={10} />
              </button>
            </LinkComponent>
          </React.Fragment>
        )}
        <Flex
          paddingX={[5, null, null, 14]}
          justifyContent="space-between"
          width={1}
          height={['56px', null, null, '66px']}
          className={'hideDesktop'}
        >
          <Flex width="110px">
            <Flex
              marginLeft="-12px"
              height="100%"
              as="button"
              paddingX={3}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={
                ui.activeMenu === 'nav'
                  ? [styles.button, styles.menuButton, styles.active].join(' ')
                  : [styles.button, styles.menuButton].join(' ')
              }
              onClick={() => {
                if (ui.activeMenu === 'nav') {
                  ui.setActiveMenu('')
                  toggleBodyClass('no-scroll', false)
                } else {
                  ui.setActiveMenu('nav')
                  toggleBodyClass('no-scroll')
                }
              }}
            >
              <div />
              <div />
              <div />
            </Flex>
            <Flex
              height="100%"
              paddingX={3}
              onClick={(e: any) => {
                e.preventDefault()
                if (ui.activeMenu === 'search') {
                  ui.setActiveMenu('')
                  toggleBodyClass('no-scroll', false)
                } else {
                  new Promise((resolve: any) => {
                    ui.setActiveMenu('search')
                    resolve()
                  }).then(() => {
                    const searchInput = document.getElementById('searchInput')
                    if (searchInput) {
                      searchInput.focus()
                      toggleBodyClass('no-scroll')
                    }
                  })
                }
              }}
              justifyContent="center"
              alignItems="center"
              className={styles.button}
              as="button"
              aria-label={intl.formatMessage(messages.toggleSearch)}
            >
              {ui.activeMenu !== 'search' ? (
                <SearchIcon className={styles.mobileSearchIcon} />
              ) : (
                <Flex
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  className={styles.close}
                >
                  <div />
                  <div />
                  <div />
                </Flex>
              )}
            </Flex>
          </Flex>
          <LinkComponent
            onClick={() => {
              setActiveNav(-1)
              ui.setActiveMenu('')
            }}
            href="/"
            className={styles.logoWrapper}
            aria-label="Emma S"
          >
            <LogoIcon />
          </LinkComponent>
          <Flex justifyContent="flex-end" width="110px">
            <Flex
              height="100%"
              paddingX={3}
              justifyContent="center"
              alignItems="center"
              className={styles.button}
              onClick={() => {
                ui.setActiveMenu('favorites')
              }}
              aria-label={intl.formatMessage(messages.showFavorites)}
              as="button"
            >
              <HeartIcon className={styles.heart} />
              {cart.favoriteCount > 0 && (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  className={styles.favCount}
                >
                  {cart.favoriteCount}
                </Flex>
              )}
            </Flex>
            <CartButton />
          </Flex>
        </Flex>
        {ui.activeMenu === 'search' && (
          <Flex
            height={['56px', null, null, '66px']}
            alignItems="center"
            paddingX={[5, null, null, 14]}
            className={styles.search}
          >
            <NavSearch uspVisible={showUsp} popularSearches={popularSearches} />
          </Flex>
        )}
        <Flex
          height={['56px', null, null, '66px']}
          width={1}
          as="nav"
          className={classNames('hideMobile', styles.navWrapperDesktop)}
        >
          <LinkComponent
            onClick={() => {
              setActiveNav(-1)
              ui.setActiveMenu('')
            }}
            className={['hideMobile', styles.logoWrapper].join(' ')}
            href="/"
            ariaLabel="Emma S"
          >
            <LogoIcon />
          </LinkComponent>

          <Flex width={1}>
            <Flex
              flexShrink={0}
              // marginLeft={[6]}
              paddingTop={'2px'}
              paddingX={4}
              className={styles.desktopNavItems}
              ref={desktopNavItems}
            >
              {navItems &&
                navItems.length > 0 &&
                navItems.map((navItem: any, index: number) =>
                  navItem.subMenus && navItem.subMenus.length > 0 ? (
                    <Flex
                      key={`desk_topnav_${navItem.slug}_${navItem.title}`}
                      onClick={() => {
                        if (index === activeNav) {
                          setActiveNav(-1)
                          ui.setActiveMenu('')
                        } else {
                          setActiveNav(index)
                          ui.setActiveMenu('nav')
                        }
                      }}
                      className={
                        activeNav === index && ui.activeMenu === 'nav'
                          ? [styles.navButton, styles.activeButton].join(' ')
                          : styles.navButton
                      }
                      aria-pressed={
                        activeNav === index && ui.activeMenu === 'nav'
                      }
                      id={`desktopnav-${navItem.slug}`}
                      as="button"
                      alignItems="center"
                      // paddingX={4}
                      paddingBottom={[null, null, null, '1px']}
                    >
                      {navItem.title}
                      <Box className={styles.chev} marginLeft={2}>
                        <ChevronDown width={12} height={7} />
                      </Box>
                    </Flex>
                  ) : (
                    <LinkComponent
                      className={[styles.navLink, styles.navButton].join(' ')}
                      onClick={() => {
                        setActiveNav(-1)
                        ui.setActiveMenu('')
                      }}
                      key={`${navItem.slug}_${navItem.title}`}
                      href={navItem.slug}
                    >
                      <Flex
                        paddingBottom={[null, null, null, '1px']}
                        className={
                          activeNav === index ? styles.activeButton : ''
                        }
                        alignItems="center"
                        // paddingX={4}
                        tabIndex={-1} // since we have a link as a parent (why..?) we dont want to focus the button inside
                      >
                        <div className={styles.labelWrap}>{navItem.title}</div>
                      </Flex>
                    </LinkComponent>
                  )
                )}
            </Flex>
            <Flex flexShrink={0} className="hideMobile" ml="auto">
              {faqRef && (
                <Box
                  as="button"
                  className={classNames(styles.button, styles.faqButton)}
                  onClick={() => {
                    ui.setActiveMenu('faq')
                  }}
                >
                  <span className={styles.labelWrap}>{faqRef.title}</span>
                </Box>
              )}
              <Flex
                height="100%"
                flexShrink={0}
                justifyContent="center"
                alignItems="center"
                as="button"
                aria-label={intl.formatMessage(
                  messages.changeLanguageAndCurrency
                )}
                // marginTop={[null, null, null, '1px']}
                onClick={() => {
                  ui.setActiveMenu('country')
                }}
                className={[styles.button].join(' ')}
              >
                {/* eslint-disable-next-line */}
                <span className={styles.labelWrap}>
                  {`${locale && locale.toUpperCase()} / ${cart.currency}`}
                </span>
              </Flex>
              <Flex
                height="100%"
                flexShrink={0}
                justifyContent="center"
                alignItems="center"
                className={styles.button}
                onClick={() => {
                  if (cart.isLoggedIn) {
                    router.push('/profile')
                    ui.setActiveMenu('')
                  } else {
                    ui.setActiveMenu('login')
                  }
                }}
                as="button"
                aria-label={intl.formatMessage(
                  cart.isLoggedIn ? messages.showProfile : messages.showLogin
                )}
              >
                <LoginIcon />
                {cart.isLoggedIn && <Box className={styles.loggedIn} />}
              </Flex>
              <Flex
                height="100%"
                flexShrink={0}
                justifyContent="center"
                alignItems="center"
                className={[styles.button, styles.buttonAdjusted].join(' ')}
                as="button"
                onClick={(e: any) => {
                  e.preventDefault()
                  if (ui.activeMenu === 'search') {
                    ui.setActiveMenu('')
                  } else {
                    new Promise((resolve: any) => {
                      ui.setActiveMenu('search')
                      resolve()
                    }).then(() => {
                      const searchInput = document.getElementById('searchInput')
                      if (searchInput) {
                        searchInput.focus()
                      }
                    })
                  }
                }}
              >
                <SearchIcon
                  className={styles.searchIcon}
                  width={19}
                  height={19}
                />
              </Flex>
              <Flex
                height="100%"
                flexShrink={0}
                justifyContent="center"
                alignItems="center"
                className={[styles.button, styles.buttonAdjusted].join(' ')}
                onClick={() => {
                  ui.setActiveMenu('favorites')
                }}
                aria-label={intl.formatMessage(messages.showFavorites)}
                as="button"
              >
                <HeartIcon className={styles.heart} />
                {cart.favoriteCount > 0 && (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    className={styles.favCount}
                  >
                    {cart.favoriteCount}
                  </Flex>
                )}
              </Flex>
              <CartButton />
            </Flex>
          </Flex>
        </Flex>

        {navItems &&
          navItems.length > 0 &&
          navItems.map((navItem: any, index: number) =>
            navItem.subMenus && navItem.subMenus.length > 0 ? (
              <Flex
                className={
                  activeNav === index && ui.activeMenu === 'nav'
                    ? [styles.subNavItem, styles.active].join(' ')
                    : styles.subNavItem
                }
                key={`nav_${navItem.slug}_${navItem.title}`}
                width={1}
                as="nav"
                aria-label={navItem.title}
              >
                <button
                  className="visuallyHidden"
                  ref={index === activeNav ? firstNavItem : null}
                  onClick={() => {
                    setActiveNav(-1)
                    ui.setActiveMenu('')
                  }}
                >
                  {intl.formatMessage(messages.backToMainNavigationFrom)}{' '}
                  {' ' + navItem.title}
                </button>
                {navItem.subMenus &&
                  navItem.subMenus.map((subItem: any, subMenuIndex: number) => (
                    <Flex
                      key={`subLink_left_${subItem.title}`}
                      alignItems="flex-start"
                      flexDirection={['column']}
                      width={navItem.subMenus.length > 1 ? 1 / 4 : 1 / 2}
                      paddingY={[10]}
                      paddingX={[14]}
                      aria-label={subItem.title}
                      as="section"
                      className={styles.leftLinks}
                    >
                      <Box
                        marginBottom={2}
                        as="h2"
                        className={styles.subLinkTitle}
                      >
                        {subItem.title}
                      </Box>
                      {subItem.links &&
                        subItem.links.map((link: any, subItemIndex: number) => (
                          <Box
                            marginBottom={2}
                            className={styles.subLink}
                            key={`sub_${link.slug}_${link.title}`}
                          >
                            <HoverLink
                              onClick={() => {
                                setActiveNav(-1)
                                ui.setActiveMenu('')
                              }}
                              slug={link.slug}
                              title={link.title}
                            />
                          </Box>
                        ))}
                    </Flex>
                  ))}
                <Flex
                  alignItems="flex-start"
                  justifyContent="flex-end"
                  width={1 / 2}
                  marginLeft="auto"
                  paddingY={[8]}
                  paddingRight={14}
                >
                  {navItem.highlights &&
                    navItem.highlights.length > 0 &&
                    navItem.highlights.map((highlight: any) => {
                      return highlight &&
                        highlight.link &&
                        highlight.link.slug ? (
                        <LinkComponent
                          href={highlight.link.slug}
                          key={highlight.link.slug}
                          className={styles.highlightWrapper}
                          onClick={() => {
                            setActiveNav(-1)
                            ui.setActiveMenu('')
                          }}
                        >
                          <Box className={styles.highlight} width={1}>
                            {highlight.image && highlight.image.url && (
                              <Image
                                alt={highlight.image.title}
                                srcSet={getContentfulSrcSet(
                                  highlight.image.url
                                )}
                              />
                            )}
                            <Flex
                              flexDirection="column"
                              justifyContent="flex-end"
                              alignItems="flex-start"
                              paddingBottom={[4]}
                              className={styles.highlightContent}
                            >
                              <Box className={styles.title} as="span">
                                {highlight.title}
                              </Box>
                              <Box className={styles.cta} as="span">
                                {highlight.cta}
                              </Box>
                            </Flex>
                          </Box>
                        </LinkComponent>
                      ) : null
                    })}
                </Flex>
                <button
                  className="visuallyHidden"
                  onClick={() => {
                    setActiveNav(-1)
                    ui.setActiveMenu('')
                  }}
                >
                  {intl.formatMessage(messages.backToMainNavigationFrom)}{' '}
                  {' ' + navItem.title}
                </button>
              </Flex>
            ) : null
          )}
        {navItems && <MobileNav uspVisible={showUsp} nav={navItems} />}
      </Flex>
    </div>
  )
}

export default observer(Header)
