import React from 'react'
import { observer } from 'mobx-react-lite'

import { CustomError } from 'src/stores/error/errors'

import { Props, State } from './types'

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: null,
  }

  componentDidMount() {
    console.log('boundary', this.props)
  }

  static getDerivedStateFromError(error: CustomError) {
    // only display errors, not warnings or info
    return !error.level || error.level === 'error' ? { error } : { error: null }
  }

  componentDidCatch(error: CustomError, errorInfo: any) {
    const { error: errorStore } = this.props
    errorStore?.report(error)
  }

  render() {
    const { error } = this.state
    return error ? (
      <div>
        <h1>Fatal error: {error.message}</h1>
      </div>
    ) : (
      this.props.children
    )
  }
}

export default observer((props) => <ErrorBoundary {...props} />)
