import { useState, useEffect } from 'react'

import { getCentraProducts } from 'src/utils/category'
import { parseCentraProductToAinoProduct } from 'src/utils/parser'

import type { AinoProduct, Maybe } from 'src/apollo/types'

export const useCentraProducts = ({
  products,
  cart,
  locale,
}: {
  products: Maybe<Maybe<AinoProduct>[]> | undefined
  cart: any
  locale: string | undefined
}) => {
  const [centraProducts, setCentraProducts] = useState<AinoProduct[]>([])

  useEffect(() => {
    const fetchCentraProducts = async () => {
      try {
        const productIds: any = products
          ?.map((product: any) => product.id)
          .filter((id) => id)
        const response: any = await getCentraProducts({
          token: cart.token,
          productIds: productIds,
          locale: locale,
        })
        if (response.products) {
          const parsedProducts = response.products.map((product: any) =>
            parseCentraProductToAinoProduct(product)
          )
          setCentraProducts(parsedProducts)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchCentraProducts()
  }, [cart.token, cart.country, products, locale])

  return { centraProducts }
}
