export const imageSizes = [400, 800, 1600, 2400]
type GenericObject = { [key: string]: string }

const getFormatString = () => {
  if (typeof window !== 'undefined' && document) {
    if (document.documentElement.classList.contains('avif')) {
      return '&fm=avif'
    } else if (document.documentElement.classList.contains('webp')) {
      return '&fm=webp'
    }
  }
  return ''
}

export function getContentfulSrcSet(url: string) {
  const srcSet: { [key: string]: string } = {}
  imageSizes.forEach((size: number) => {
    if (size > 1000) {
      srcSet[size] = `${url}?w=${size}${getFormatString()}`
    } else if (size > 100) {
      srcSet[size] = `${url}?w=${size}${getFormatString()}`
    }
  })
  return srcSet
}

export function getCentraSrcSet(fullUrl: string): GenericObject {
  const srcSet: GenericObject = {}
  imageSizes.forEach((size: number) => {
    if (size > 100 && fullUrl) {
      srcSet[size] = fullUrl.replace(/\.jpg/, `-${size}.jpg`)
    }
  })
  return srcSet
}
