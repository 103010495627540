import axios from 'axios'

import { FilterTypes } from 'src/components/ProductsContainer/ProductsContainer'

export const checkoutApi = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API
  ? process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API
  : 'https://emmas.centraqa.com/api/checkout/'

export const createCart = async (token?: string) => {
  let headers = {}
  if (token) {
    headers = {
      ...headers,
      'API-Token': token,
    }
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}selection`,
    method: 'GET',
    headers,
  })

  return data
}

export const refreshCart = async (token: string) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}selection`,
    method: 'GET',
    headers,
  })
  return data
}

export const addItem = async (token: string, item: string) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}items/${item}`,
    method: 'POST',
    headers,
  })
  return data
}

export const updateItemQuantity = async (
  token: string,
  line: string,
  quantity: string
) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}lines/${line}/quantity/${quantity}`,
    method: 'PUT',
    headers,
  })
  return data
}

export const removeItem = async (token: string, line: string) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}lines/${line}`,
    method: 'DELETE',
    headers,
  })
  return data
}

export const changeCountry = async (token: string, country: string) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}countries/${country}`,
    method: 'PUT',
    headers,
  })
  return data
}

export const fetchCentraProduct = async (
  token: string,
  productId: string | number
) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}products/${productId}`,
    headers,
  })
  return data
}

export const fetchCentraProductBySlug = async (token: string, slug: string) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    method: 'POST',
    url: `${checkoutApi}uri/`,
    headers,
    data: {
      uri: slug,
      for: ['product'],
    },
  })
  return data
}

export const fetchCentraProducts = async ({
  token,
  productIds,
  options,
  locale,
}: {
  token: string
  productIds: string[] | number[]
  options?: FilterTypes
  locale: string | undefined
}) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }
  let sort: any = []

  if (options && options.search && options.search === '') {
    delete options.search
  }

  if (options && options.sort && options.sort !== '') {
    if (options.sort === 'priceAsc') {
      sort = [
        {
          field: 'priceAsNumber',
          order: 'asc',
        },
      ]
    }
    if (options.sort === 'priceDesc') {
      sort = [
        {
          field: 'priceAsNumber',
          order: 'desc',
        },
      ]
    }
  }
  if (options) {
    delete options.sort
  }

  const { data } = await axios({
    method: 'POST',
    url: `${checkoutApi}products/`,
    data: {
      products: productIds,
      ...options,
      sortOrder: sort,
      language: locale,
    },
    headers,
  })
  return data
}

export const loginCentraUser = async (
  token: string,
  email: string,
  password: string
) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}login/${email}`,
    method: 'POST',
    headers,
    data: {
      password: password,
    },
  })
  return data
}
export const registerCentraUser = async ({
  token,
  email,
  password,
  firstName,
  lastName,
  locale,
}: {
  token: string
  email: string
  password: string
  firstName: string
  lastName: string
  locale: string
}) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}register`,
    method: 'POST',
    headers,
    data: {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      language: locale,
    },
  })
  return data
}
export const getCentraUser = async (token: string) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}customer`,
    method: 'GET',
    headers,
  })
  return data
}
export const getCentraUserOrders = async (token: string) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}orders`,
    method: 'POST',
    headers,
  })
  return data
}

export const logoutCentraUser = async (token: string) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}logout`,
    method: 'POST',
    headers,
  })
  return data
}
export const sendCentraResetPasswordEmail = async (
  token: string,
  email: string,
  linkUri: string
) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}password-reset-email/${email}`,
    method: 'POST',
    headers,
    data: {
      linkUri,
    },
  })
  return data
}

export const resetCentraPassword = async (
  token: string,
  i: string,
  id: string,
  newPassword: string
) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}password-reset`,
    method: 'POST',
    headers,
    data: {
      i,
      id,
      newPassword,
    },
  })
  return data
}

export const subscribeNewsletter = async ({
  token,
  email,
  locale,
}: {
  token: string
  email: string
  locale: string
}) => {
  let headers: any = {
    'API-Token': token,
  }
  if (!process.browser) {
    headers = {
      ...headers,
      'API-Authorization': process.env.CENTRA_CHECKOUT_SERVER_API_TOKEN,
    }
  }

  const { data } = await axios({
    url: `${checkoutApi}newsletter-subscription/${email}`,
    method: 'POST',
    headers,
    data: {
      language: locale,
    },
  })
  return data
}

export const search = async (token: string, searchPhrase: string) => {
  const headers: any = {
    'API-Token': token,
  }

  const { data } = await axios({
    url: `${checkoutApi}products`,
    method: 'POST',
    headers,
    data: {
      search: searchPhrase,
    },
  })
  return data
}

export const setCentraLanguage = async (token: string, locale: string) => {
  const headers: any = {
    'API-Token': token,
  }
  const { data } = await axios({
    url: `${checkoutApi}languages/${locale}`,
    method: 'PUT',
    headers,
  })
  return data
}
