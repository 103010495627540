import { Level } from './types'

export class CustomError extends Error {
  level: Level
  originalError?: Error
  constructor(message: string, error?: Error) {
    super(message)
    this.name = 'Error'
    this.level = 'error'
    this.originalError = error
    this.stack = error && error.stack ? error.stack : new Error().stack
  }
}

export class Warning extends CustomError {
  constructor(message: string, error?: Error) {
    super(message, error)
    this.name = 'Warning'
    this.level = 'warning'
  }
}

export class Info extends CustomError {
  constructor(message: string, error?: Error) {
    super(message, error)
    this.name = 'Info'
    this.level = 'info'
  }
}

export class Fatal extends CustomError {
  constructor(message: string, error?: Error) {
    super(message, error)
    this.name = 'Fatal'
  }
}
