export function decode(string: string) {
  const txt = new DOMParser().parseFromString(string, 'text/html')

  return txt.documentElement.textContent
}

export function toggleBodyClass(className: string, add = true) {
  if (!document || !document.body) return

  if (add) {
    document.body.classList.add(className)
  } else {
    document.body.classList.remove(className)
  }
}
