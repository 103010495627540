import { FormattedMessage, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import PasswordInput from 'src/components/PasswordInput/PasswordInput'
import PrimaryButton from 'src/components/UI/PrimaryButton/PrimaryButton'
import loginSchema from 'src/schemas/login'
import messages from 'src/stores/error/messages'
import useStore from 'src/stores/useStore'
import { Box, Flex } from 'src/components/UI/Grid/Grid'
import Spinner from 'src/components/UI/Spinner/Spinner'

import styles from './LoginForm.module.scss'

const LoginForm = ({ isMenu }: { isMenu?: boolean }) => {
  const { cart, error, ui } = useStore()
  const [pending, setPending] = useState<boolean>(false)
  const intl = useIntl()
  const router = useRouter()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(loginSchema),
  })

  const onSubmit = async (data: any) => {
    const { email, password } = data

    setPending(true)
    try {
      const response = await cart.loginUser(email, password)
    } catch (e: any) {
      error.warn(messages.loginUser, e)
      return
    } finally {
      setPending(false)
    }
    router.push('/profile')
    ui.setActiveMenu('')
  }

  return (
    <Flex
      marginY="auto"
      width={1}
      maxWidth={['none', null, isMenu ? 'none' : '400px']}
      alignItems="center"
      flexDirection="column"
      className={styles.login}
      paddingX={[5]}
    >
      <Box marginBottom={[7]} as="h1" className={styles.title}>
        <FormattedMessage
          defaultMessage="Log in"
          id="G+pH4U"
          description="Title text on login page"
        />
      </Box>
      <Flex
        marginBottom={[11]}
        flexDirection="column"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          {...register('email')}
          className={styles.input}
          aria-invalid={errors.email ? 'true' : 'false'}
          placeholder={intl.formatMessage({
            defaultMessage: 'Your e-mail',
            id: 'iiFDtW',
            description: 'Placeholder text for login e-mail address input',
          })}
        />
        {errors?.email && (
          <p className={styles.error}>
            <FormattedMessage
              defaultMessage="email must be a valid email"
              id="ZmGSS0"
              description="Error message in login form for email"
            />
          </p>
        )}
        <PasswordInput register={register} errors={errors} />
        {errors?.password && (
          <p className={styles.error}>
            <FormattedMessage
              defaultMessage="password must be at least 6 characters"
              id="65QxqM"
              description="Error message in login form for password"
            />
          </p>
        )}
        <PrimaryButton>
          {pending ? (
            <Spinner
              className={styles.spinner}
              color="#fff"
              width={25}
              height={25}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Log in"
              id="r4gEDI"
              description="Text on login button on login page"
            />
          )}
        </PrimaryButton>
      </Flex>
      {!isMenu && (
        <Flex
          className={styles.links}
          flexDirection="column"
          alignItems="center"
        >
          <Flex>
            <LinkComponent
              onClick={() => {
                ui.setActiveMenu('')
              }}
              className={styles.link}
              href="/forgot-password"
            >
              <FormattedMessage
                defaultMessage="Forgot password"
                id="LWoFS1"
                description="A link text login page"
              />
            </LinkComponent>
          </Flex>
          <Flex marginTop="16px">
            <Box as="p">
              <FormattedMessage
                defaultMessage="No account?"
                id="oN+p+Z"
                description="A text on login page for asking if a user doesn't have an account"
              />
            </Box>
            <Box marginLeft={2} as="span">
              <LinkComponent
                onClick={() => {
                  ui.setActiveMenu('')
                }}
                className={styles.link}
                href="/signup"
              >
                <FormattedMessage
                  defaultMessage="Create one"
                  id="Qggrjb"
                  description="A link text on login page"
                />
              </LinkComponent>
            </Box>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default LoginForm
