import { FormattedMessage, useIntl } from 'react-intl'
import { Fragment, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import FAQSubject from 'src/components/FAQ/FAQSubject'
import useStore from 'src/stores/useStore'
import { CloseIcon, PlusIcon, SearchIcon } from 'src/components/UI/Icons/Icons'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import SlideOver from 'src/components/SlideOver/SlideOver'

import styles from './FAQMenu.module.scss'

import type {
  ContentfulFaqQuestion,
  ContentfulFaqSubject,
  Maybe,
} from 'src/apollo/types'

const InnerMenu = observer(({ faqConfig }: any): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('')
  const [results, setResults] = useState<any>([])
  const [fuse, setFuse] = useState<any>(null)
  const intl = useIntl()

  const questions: Maybe<ContentfulFaqQuestion>[] = []

  const subjects =
    faqConfig &&
    faqConfig.entries &&
    faqConfig.entries[0] &&
    faqConfig.entries[0].subjects

  subjects.forEach((subject: ContentfulFaqSubject) => {
    if (subject && subject.questions) {
      subject.questions.forEach((question: Maybe<ContentfulFaqQuestion>) => {
        questions.push(question)
      })
    }
  })

  useEffect(() => {
    const initFuse = async () => {
      const Fuse = (await import('fuse.js')).default
      const fuse = new Fuse(questions, {
        ignoreLocation: true,
        includeScore: true,
        keys: [
          'title',
          // 'answer'
        ],
        minMatchCharLength: 2,
      })
      setFuse(fuse)
    }
    if (searchTerm && !fuse) {
      initFuse()
    }
  }, [fuse, searchTerm]) /* eslint-disable-line */

  const { ui } = useStore()

  const closeNav = () => {
    ui.setActiveMenu('')
  }
  return (
    <Fragment>
      <SlideOver
        active={ui.activeMenu === 'faq'}
        onCloseClick={() => {
          closeNav()
        }}
        className={
          ui.activeMenu === 'faq'
            ? [styles.wrapper, styles.active].join(' ')
            : styles.wrapper
        }
      >
        <Flex
          flexDirection="column"
          width={1}
          paddingX={[5, null, null, 10]}
          paddingTop={[10]}
        >
          <Flex
            marginBottom={5}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={styles.title} as="span">
              <FormattedMessage
                defaultMessage="FAQ"
                id="6PQeuB"
                description="FAQ title for faq menu"
              />
            </Box>
            <Flex
              alignItems="center"
              onClick={() => {
                ui.setActiveMenu('')
              }}
              className={styles.closeIcon}
              as="button"
              aria-label={intl.formatMessage({
                defaultMessage: 'Close FAQ window',
                id: 'vB/XnT',
                description: 'Label for button that closes the FAQ window',
              })}
            >
              <PlusIcon />
            </Flex>
          </Flex>
          <Flex
            className={styles.questions}
            paddingBottom={10}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Box className={styles.hero} marginBottom={8} width={1}>
              <div className={styles.inputWrapper}>
                <SearchIcon className={styles.searchIcon} />
                <input
                  onChange={async (e: any) => {
                    const value = e.target.value
                    setSearchTerm(value)
                    if (fuse) {
                      const results = fuse.search(value)
                      setResults(results)
                    }
                  }}
                  value={searchTerm}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Search...',
                    id: 'TDQLYf',
                    description: 'Placeholder for FAQ search input',
                  })}
                />
                {searchTerm !== '' && (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    as="button"
                    onClick={() => {
                      setSearchTerm('')
                    }}
                    className={styles.clear}
                  >
                    <PlusIcon width={18} height={18} />
                  </Flex>
                )}
              </div>
            </Box>
            {searchTerm === '' || searchTerm.length < 2 ? (
              subjects &&
              subjects.map((subject: any) => {
                return (
                  <Flex
                    key={subject.id}
                    className={styles.subject}
                    flexDirection={'column'}
                  >
                    <FAQSubject small subject={subject} />
                  </Flex>
                )
              })
            ) : (
              <Flex
                justifyContent="center"
                alignItems="center"
                width={1}
                paddingX={[0, null, null, 0]}
                flexDirection={['column']}
                marginBottom={10}
              >
                {results &&
                  results.map((question: any) => {
                    let title = question.item.title || ''
                    const answer = question.item.answer || ''
                    const reg = new RegExp(searchTerm, 'gi')
                    let hasMark = false
                    title = title.replace(reg, function (str: string) {
                      if (str) {
                        hasMark = true
                      }
                      return '<mark>' + str + '</mark>'
                    })

                    return question && hasMark ? (
                      <Flex
                        paddingX={0}
                        marginBottom={[6]}
                        flexDirection="column"
                        key={question.id}
                        width={[1]}
                      >
                        <Box marginBottom={0}>
                          <p
                            className={styles.question}
                            dangerouslySetInnerHTML={{ __html: title }}
                          />
                        </Box>
                        <p
                          className={styles.answer}
                          dangerouslySetInnerHTML={{ __html: answer }}
                        />
                      </Flex>
                    ) : null
                  })}
                <Box width={[1, null, null, 1 / 2]} />
              </Flex>
            )}
          </Flex>
        </Flex>
      </SlideOver>
    </Fragment>
  )
})

const FAQMenu = ({ faqConfig }: any): JSX.Element | null => {
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    setShowMenu(true)
  }, [])

  if (!showMenu) {
    return null
  }

  return <InnerMenu faqConfig={faqConfig} />
}

export default FAQMenu
