import English from 'src/json/locales/en.json' /* eslint-disable-line */
import Swedish from 'src/json/locales/sv.json' /* eslint-disable-line */
import i18n from 'src/i18n.json'

const { defaultLocale, locales } = i18n

const SITE_URL: string = process.env.NEXT_PUBLIC_SITE_URL || ''

export const getMessagesByLocale = (locale: string | undefined) => {
  let intlMessages
  switch (locale) {
    case 'sv':
      intlMessages = Swedish
      break
    default:
      intlMessages = English
  }
  return intlMessages
}

export const getIntlUrl = (url = '', locale = '') => {
  // If your local SITE_URL doesn’t match the one in Vercel (http://localhost:4230/),
  // create a .env.local file with your local url, f ex:
  // NEXT_PUBLIC_SITE_URL='https://emmas.local.aino.vip/'
  let localUrl = url.replace(SITE_URL, '')
  // Only handle internal urls
  if (
    /^http(s?)\:\/\//.test(localUrl) ||
    /^tel:/.test(localUrl) ||
    /^mailto:/.test(localUrl)
  ) {
    return localUrl
  }
  // Only handle existing locales
  if (!locales.includes(locale)) {
    return localUrl
  }
  // Make sure we don’t have a leading slash
  if (localUrl.startsWith('/')) {
    localUrl = localUrl.substring(1)
  }
  // Don’t prefix prefixed urls
  const urlParts = localUrl.split('/')
  if (locales.includes(urlParts[0])) {
    urlParts.shift()
  }
  localUrl = urlParts.join('/')

  const prefix = locale === defaultLocale ? '' : `/${locale}`

  return `${prefix}/${localUrl}`
}
