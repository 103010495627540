import * as React from 'react'

import Ui from './ui/ui'
import Cart from './cart/cart'
import Error from './error/error'
import { Stores } from './types'

export const stores: Stores = {
  ui: new Ui(),
  cart: new Cart(),
  error: new Error(),
}

export const StoresContext = React.createContext<Stores>(stores)

const StoresProvider: React.FC = ({ children }) => (
  <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>
)

export default StoresProvider
