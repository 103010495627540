import { useIntl } from 'react-intl'

import Container from 'src/components/UI/Container/Container'
import Newsletter from 'src/components/Newsletter/Newsletter'
import { Flex, Box } from 'src/components/UI/Grid/Grid'

import styles from './Footer.module.scss'
import HoverLink from '../HoverLink/HoverLink'

import type { Props } from './types'

const Footer = ({ navLinks, newsletterText }: Props) => {
  const intl = useIntl()
  const openCookieBot = () => {
    /* eslint-disable */
    // @ts-ignore
    if (typeof Cookiebot !== 'undefined') {
      // @ts-ignore
      Cookiebot.renew()
    }
    /* eslint-enable */
  }
  return (
    <Flex
      marginTop="auto"
      as="footer"
      flexDirection="column"
      className={styles.wrapper}
    >
      <Box paddingX={[0, null, null, 16]}>
        <Flex flexDirection={['column', null, null, 'row']} width={1}>
          <Newsletter newsletterText={newsletterText} />
          <Flex
            className={styles.navigation}
            width={[1, null, null, 6 / 8]}
            paddingY={[10, null, null, 16]}
            paddingLeft={[6, null, null, 16]}
            flexDirection="row"
            as="nav"
          >
            {navLinks &&
              navLinks.length > 0 &&
              navLinks.map((nav: any, index: number) => (
                <Flex
                  key={index}
                  alignItems="flex-start"
                  flexDirection="column"
                  marginBottom={[31, null, null, 0]}
                  width={[1, null, null, null]}
                  marginLeft={[0, null, null, '5vw']}
                  marginRight={[0, null, null, '5vw']}
                >
                  {nav &&
                    nav.links.length > 0 &&
                    nav.links.map((navItem: any, index: number) => (
                      <HoverLink
                        key={`${index}-${navItem.slug}`}
                        slug={navItem.slug}
                        title={navItem.title}
                        white
                      />
                    ))}
                  {index === 0 && (
                    <HoverLink
                      isButton
                      title={intl.formatMessage({
                        defaultMessage: 'Change cookie settings',
                        description: 'Change cookie link in footer',
                        id: 'A1j2x0',
                      })}
                      white
                      onClick={openCookieBot}
                    />
                  )}
                </Flex>
              ))}
          </Flex>
        </Flex>
      </Box>
      <Container paddingX={[0, null, null, 16]}>
        <Box className={styles.copyright} as="span">
          {`${String.fromCharCode(169)} ${new Date()
            .getFullYear()
            .toString()} Emma S. AB`}
        </Box>
      </Container>
    </Flex>
  )
}

export default Footer
