import type { ContentfulSeo, Maybe } from 'src/apollo/types'

export const getSeo = (seoData: Maybe<ContentfulSeo> | undefined) => {
  if (!seoData) {
    return {}
  }
  const seoTitle = seoData.title
  const seoDescription = seoData.description
  const seoImage = seoData.image ? seoData.image.url : null

  return {
    seoTitle,
    seoDescription,
    seoImage,
  }
}
