export type TrackableProduct = {
  categories?: any[]
  discount?: number
  item?: string
  brand?: string
  name: string
  price: number
  quantity?: number
  size?: string
  sku: string
  variant?: string
}

const flattenCategories = (categories: any): string[] => {
  let categoryNames

  if (typeof categories[0] === 'string') {
    // If this is a flat array already, do nothing
    categoryNames = categories
  } else {
    // If it’s not, flatten it
    categoryNames = categories
      .map((cat: any) => cat.name)
      .map((cat: any) => cat[cat.length - 1])
  }
  // Now filter out unnecessary category
  // TODO: Is this true for all stores? Probably not.
  categoryNames = categoryNames.filter(
    (categoryName: string) =>
      categoryName !== 'All Products' && categoryName !== 'Hela sortimentet'
  )

  return categoryNames
}

export const generateGtmCategories = (categoryNames: string[]) => {
  const categories: any = {}
  for (let i = 0; i < categoryNames.length; i++) {
    categories[`item_category${i > 0 ? i + 1 : ''}`] = categoryNames[i]
  }

  return categories
}

const Analytics = {
  track(event: any, payload: any) {
    // Track an event.
    // List of valid events: https://developers.google.com/analytics/devguides/collection/ga4/reference/events
    // (These are also valid for Universal Analytics:
    //  https://developers.google.com/analytics/devguides/collection/gtagjs/events)

    // See also: https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
    // and
    // https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce
    if (typeof window !== 'undefined') {
      let dataLayer: any = {}
      let value: number | undefined
      if (payload.hasOwnProperty('value')) {
        value = payload.value
      } else if (payload.products) {
        value = payload.products.reduce(
          (a: TrackableProduct, b: TrackableProduct) => a.price + b.price,
          0
        )
      } else if (payload.product) {
        value = payload.product.price
      }
      let currency: string | undefined
      if (payload.currency) {
        currency = payload.currency
      }
      let categories: Record<string, string> | undefined
      if (payload.product && payload.product.categories) {
        // Google don’t want an array of categories, they want awkwardly named keys and values

        const categoryNames = flattenCategories(payload.product.categories)
        categories = generateGtmCategories(categoryNames)
      }
      switch (event) {
        case 'add_to_cart':
          dataLayer = {
            event: 'add_to_cart',
            ecommerce: {
              currency,
              value,
              items: [
                {
                  item_id: payload.product.sku,
                  item_name: payload.product.name,
                  item_brand: payload.product.brand,
                  item_variant: payload.product.variant,
                  affiliation: 'Webshop',
                  discount: payload.product.discount,
                  index: 0,
                  price: payload.product.price,
                  quantity: 1,
                  ...categories,
                },
              ],
            },
          }
          break

        case 'remove_from_cart':
          dataLayer = {
            event: 'remove_from_cart',
            ecommerce: {
              currency,
              items: [
                {
                  item_id: payload.product.sku,
                  item_name: payload.product.name,
                  price: payload.product.price,
                  quantity: 1,
                },
              ],
            },
          }
          break

        case 'view_item':
          dataLayer = {
            event: 'view_item',
            ecommerce: {
              currency,
              value,
              items: [
                {
                  item_id: payload.product.sku,
                  item_name: payload.product.name,
                  item_brand: payload.product.brand,
                  item_variant: payload.product.variant,
                  price: payload.product.price,
                  affiliation: 'Webshop',
                  discount: payload.product.discount,
                  index: 0,
                  quantity: 1,
                  ...categories,
                },
              ],
            },
          }

          break

        case 'view_item_list':
          dataLayer = {
            event: 'view_item_list',
            ecommerce: {
              currency,
              value,
              item_list_id: payload.listId,
              item_list_name: payload.listName,
              items: payload.products
                ? payload.products.map((product: TrackableProduct) => {
                    return {
                      item_id: product.sku,
                      item_name: product.name,
                      price: product.price,
                      quantity: 1,
                    }
                  })
                : [],
            },
          }
          break

        case 'begin_checkout':
          dataLayer = {
            event: 'begin_checkout',
            ecommerce: {
              currency,
              value,
              items: payload.products
                ? payload.products.map((product: TrackableProduct) => {
                    return {
                      item_id: product.sku,
                      item_name: product.name,
                      item_brand: product.brand,
                      item_variant: product.variant,
                      price: product.price,
                      discount: product.discount,
                      affiliation: 'Webshop',
                      quantity: product.quantity || 1,
                      ...product.categories,
                    }
                  })
                : [],
            },
          }
          break

        case 'add_shipping_info':
          dataLayer = {
            event: 'add_shipping_info',
            ecommerce: {
              currency,
              value,
              shipping_tier: payload.shipping_tier,
              items: payload.products
                ? payload.products.map((product: TrackableProduct) => {
                    return {
                      item_id: product.sku,
                      item_name: product.name,
                      item_brand: product.brand,
                      item_variant: product.variant,
                      price: product.price,
                      discount: product.discount,
                      affiliation: 'Webshop',
                      quantity: product.quantity || 1,
                      ...product.categories,
                    }
                  })
                : [],
            },
          }
          break

        case 'add_payment_info':
          dataLayer = {
            event: 'add_payment_info',
            ecommerce: {
              currency,
              value,
              payment_type: payload.payment_type,
              items: payload.products
                ? payload.products.map((product: TrackableProduct) => {
                    return {
                      item_id: product.sku,
                      item_name: product.name,
                      item_brand: product.brand,
                      item_variant: product.variant,
                      price: product.price,
                      discount: product.discount,
                      affiliation: 'Webshop',
                      quantity: product.quantity || 1,
                      ...product.categories,
                    }
                  })
                : [],
            },
          }
          break

        case 'purchase':
          dataLayer = {
            event: 'purchase',
            ecommerce: {
              currency,
              value,
              transaction_id: payload.orderId,
              shipping: payload.shipping,
              coupon: payload.coupon,
              items: payload.products
                ? payload.products.map((product: TrackableProduct) => {
                    return {
                      item_id: product.sku,
                      item_name: product.name,
                      item_brand: product.brand,
                      item_variant: product.variant,
                      price: product.price,
                      discount: product.discount,
                      affiliation: 'Webshop',
                      quantity: product.quantity || 1,
                      ...product.categories,
                    }
                  })
                : [],
            },
          }

          break

        default:
          dataLayer = payload
      }

      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        if (dataLayer && dataLayer.event) {
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push(dataLayer)
          console.log('Tracking', event, dataLayer)
        }
      }
    }
  },

  pageStart(nextUrl: string, useReferrer?: boolean, previous_url?: string) {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      const dataLayer = {
        event: 'virtual_page_view',
        page_url: nextUrl,
        previous_url: useReferrer ? document.referrer : previous_url || null,
      }
      window.dataLayer.push(dataLayer)
      console.log('Tracking', 'virtual_page_view', dataLayer)
    }
  },
  pageEnd() {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      const dataLayer = {
        event: 'virtual_dom_loaded',
        page_title: document.title,
      }
      window.dataLayer.push(dataLayer)
      console.log('Tracking', 'virtual_dom_loaded', dataLayer)
    }
  },
}

export default Analytics
