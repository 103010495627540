import { Helmet } from 'react-helmet'
import { useRouter } from 'next/router'

import type { Props } from './types'

const SEO = ({
  canonical,
  children,
  lang,
  noIndex = false,
  seoMetaDescription,
  seoOgImage,
  seoTitle,
  type,
  title,
}: Props): JSX.Element => {
  // const props = {
  //   bodyAttributes: {
  //     // class: bodyClass || '',
  //   },
  //   htmlAttributes: {
  //     lang,
  //   },
  // }

  if (seoOgImage && seoOgImage.startsWith('//')) {
    seoOgImage = `https:${seoOgImage}`
  }

  const router = useRouter()
  const { locales, defaultLocale, locale } = router

  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      {locales &&
        locales.map((loc) => {
          const prefix = loc === defaultLocale ? '' : `/${loc}`

          return (
            <link
              href={`https://emmas.com${prefix}${canonical}`}
              hrefLang={loc}
              key={`hreflang_${loc}`}
              rel="alternate"
            />
          )
        })}

      <link rel="preconnect" href="https://images.ctfassets.net" />
      <link rel="preconnect" href="https://static.lipscore.com" />

      {/*
      <link rel="apple-touch-icon" sizes="180x180" href={appleTouch} />
      <link rel="icon" type="image/png" sizes="32x32" href={s} />
      <link rel="icon" type="image/png" sizes="16x16" href={xs} />
      <link rel="mask-icon" href={safariPinnedTab} color="#111111" />
      <meta name="msapplication-TileColor" content="#f2ebe7" />
      <meta name="theme-color" content="#ffffff" />
      */}

      {noIndex && <meta name="robots" content="noindex, nofollow" />}

      {title && <title>{title}</title>}
      {seoTitle && <meta property="og:title" content={seoTitle} />}
      {seoTitle && <meta property="twitter:title" content={seoTitle} />}
      {seoTitle && <meta property="og:image:alt" content={seoTitle} />}
      {seoTitle && <meta property="twitter:image:alt" content={seoTitle} />}
      {seoMetaDescription && (
        <meta name="description" content={seoMetaDescription} />
      )}
      {seoMetaDescription && (
        <meta property="og:description" content={seoMetaDescription} />
      )}
      {seoOgImage && <meta property="og:image" content={seoOgImage} />}
      {seoOgImage && <meta property="twitter:image:src" content={seoOgImage} />}

      {type && <meta property="og:type" content={type} />}
      {type && <meta property="twitter:type" content={type} />}

      {children}
    </Helmet>
  )
}

export default SEO
