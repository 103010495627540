import AddToCart from 'src/components/AddToCart/AddToCart'
import { Box, Flex } from 'src/components/UI/Grid/Grid'
import Image from 'src/components/UI/Image/Image'
import { CentraProduct } from 'src/types'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import useStore from 'src/stores/useStore'

import styles from './UpSaleItem.module.scss'

import type { AinoProduct } from 'src/apollo/types'

const UpSaleItem = ({
  product,
  centraProduct,
}: {
  product: AinoProduct
  centraProduct: CentraProduct
}) => {
  const { ui } = useStore()
  const { categories, items, name, price, priceAsNumber, primaryImage, sku } =
    product
  return (
    <Flex flexDirection="column" className={styles.product}>
      <LinkComponent
        onClick={() => {
          ui.setActiveMenu('')
        }}
        href={product.slug ? `/product/${product.slug}` : ''}
      >
        <Box width={1} flexShrink={0} className={styles.imageWrapper}>
          {primaryImage && <Image src={primaryImage} alt={name ? name : ''} />}
          {items && items[0] && (
            <Box
              paddingRight={[2, null, null, 3]}
              paddingBottom={[2, null, null, 3]}
              className={styles.buttons}
              flexShrink={0}
            >
              <AddToCart
                small
                item={items[0]}
                product={{
                  name: name || '',
                  price: centraProduct?.priceAsNumber || priceAsNumber || 0,
                  sku: sku || '',
                  ...(categories && { categories }),
                }}
              />
            </Box>
          )}
        </Box>
        <Flex
          width={1}
          paddingY={2}
          style={{ position: 'relative' }}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Flex flexDirection="column">
            <Box className={styles.name} as="span">
              {name}
            </Box>
            <Box as="span" className={styles.price}>
              {centraProduct?.price || price}
            </Box>
          </Flex>
        </Flex>
      </LinkComponent>
    </Flex>
  )
}

export default UpSaleItem
