import type { CentraProduct } from 'src/types'
import type { AinoProduct } from 'src/apollo/types'

export const parseCentraProductToAinoProduct = (
  product: CentraProduct
): AinoProduct => {
  let media: any = []
  let primaryImage = ''
  if (!Array.isArray(product.media)) {
    media = product.media.standard
    primaryImage = media ? media[0] : ''
  }

  return {
    id: product.product,
    brand: product.brandName,
    provider: 'centra',
    canonicalUri: `/shop/${product.categoryUri}${product.uri}`,
    categories: product.categories,
    description: product.description,
    name: product.name,
    sku: product.sku,
    productTags: product.ainoProductTags ? product.ainoProductTags : null,
    slug: `${product.uri}`,
    discountPercent: product.discountPercent,
    price: product.price,
    priceAsNumber: product.priceAsNumber,
    priceBeforeDiscount: product.priceBeforeDiscount,
    priceBeforeDiscountAsNumber: product.priceBeforeDiscountAsNumber,
    showAsOnSale: product.showAsOnSale,
    showAsNew: product.showAsNew,
    items: product.items
      ? product.items.map((item) => ({
          item: item.item,
          name: item.name,
          sku: item.sku,
          ean: item.ean,
          stock: item.stock,
        }))
      : [],
    media,
    primaryImage,
  }
}
