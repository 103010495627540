import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Flex, Box } from 'reflexbox'

import styles from './PasswordInput.module.scss'

import type Props from './types'

const PasswordInput = ({ register, errors }: Props) => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  return (
    <Box className={styles.password}>
      <input
        {...register('password')}
        className={showPassword ? '' : styles.showPassword}
        type={showPassword ? 'text' : 'password'}
        aria-invalid={errors.password ? 'true' : 'false'}
        placeholder={intl.formatMessage({
          defaultMessage: 'Your password',
          id: 'dqMjc9',
          description: 'Placeholder text for login password input',
        })}
      />
      {showPassword ? (
        <Flex className={styles.showHide}>
          <Box as="span" onClick={() => setShowPassword(!showPassword)}>
            <FormattedMessage
              defaultMessage="Hide"
              id="PR4BVi"
              description="Text on input fields for password"
            />
          </Box>
        </Flex>
      ) : (
        <Flex className={styles.showHide}>
          <Box as="span" onClick={() => setShowPassword(!showPassword)}>
            <FormattedMessage
              defaultMessage="Show"
              id="B2p95O"
              description="Text on input fields for password"
            />
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default PasswordInput
