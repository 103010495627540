import { FormattedMessage, useIntl } from 'react-intl'
import { Fragment, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import useStore from 'src/stores/useStore'
import { PlusIcon } from 'src/components/UI/Icons/Icons'
import { Flex, Box } from 'src/components/UI/Grid/Grid'
import SlideOver from 'src/components/SlideOver/SlideOver'

import styles from './LoginMenu.module.scss'
import LoginForm from '../LoginForm/LoginForm'
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm'
import SignupForm from '../SignupForm/SignupForm'

const InnerLogin = observer((): JSX.Element => {
  const intl = useIntl()
  const { ui } = useStore()
  const [activeForm, setActiveForm] = useState('login')

  const closeNav = () => {
    ui.setActiveMenu('')
    setActiveForm('login')
  }

  return (
    <Fragment>
      <SlideOver
        active={ui.activeMenu === 'login'}
        onCloseClick={() => {
          closeNav()
        }}
        className={
          ui.activeMenu === 'login'
            ? [styles.wrapper, styles.active].join(' ')
            : styles.wrapper
        }
      >
        <Flex flexDirection="column" width={1} paddingX={[5, null, null, 10]}>
          <Flex
            paddingTop={[6, null, null, 10]}
            width={1}
            marginBottom={[6, null, null, 10]}
            alignItems="center"
            paddingX={[5, null, null, 10]}
            justifyContent="flex-end"
            className={styles.closeBar}
          >
            <Box
              onClick={() => {
                closeNav()
              }}
              className={styles.closeIcon}
              as="button"
              aria-label={intl.formatMessage({
                defaultMessage: 'Close log in window',
                id: 'voyf4S',
                description: 'Label for button that closes the log in window',
              })}
            >
              <PlusIcon />
            </Box>
          </Flex>
          <Flex
            marginY="auto"
            width={1}
            justifyContent="center"
            flexDirection="column"
          >
            {activeForm === 'login' && <LoginForm isMenu={true} />}
            {activeForm === 'forgotPassword' && (
              <ForgotPasswordForm isMenu={true} />
            )}
            {activeForm === 'signup' && <SignupForm isMenu={true} />}
            <Flex
              className={styles.links}
              flexDirection="column"
              alignItems="center"
            >
              {activeForm === 'login' && (
                <>
                  <Flex>
                    <Box
                      as="button"
                      onClick={() => {
                        setActiveForm('forgotPassword')
                      }}
                      className={styles.link}
                    >
                      <FormattedMessage
                        defaultMessage="Forgot password"
                        id="LWoFS1"
                        description="A link text login page"
                      />
                    </Box>
                  </Flex>
                  <Flex marginTop="16px">
                    <Box as="p">
                      <FormattedMessage
                        defaultMessage="No account?"
                        id="oN+p+Z"
                        description="A text on login page for asking if a user doesn't have an account"
                      />
                    </Box>
                    <Box marginLeft={2} as="span">
                      <Box
                        as="button"
                        onClick={() => {
                          setActiveForm('signup')
                        }}
                        className={styles.link}
                      >
                        <FormattedMessage
                          defaultMessage="Create one"
                          id="Qggrjb"
                          description="A link text on login page"
                        />
                      </Box>
                    </Box>
                  </Flex>
                </>
              )}
              {activeForm === 'signup' && (
                <>
                  <Flex>
                    <Box as="p">
                      <FormattedMessage
                        defaultMessage="Already have an account?"
                        id="lsINSH"
                        description="A text on signup page for asking if a user already has an account"
                      />
                    </Box>
                    <Box marginLeft={2} as="span">
                      <Box
                        onClick={() => {
                          setActiveForm('login')
                        }}
                        className={styles.link}
                      >
                        <FormattedMessage
                          defaultMessage="Log in"
                          id="h2oWmm"
                          description="A link text on signup page"
                        />
                      </Box>
                    </Box>
                  </Flex>
                </>
              )}
              {activeForm === 'forgotPassword' && (
                <>
                  <Flex>
                    <Box
                      onClick={() => {
                        setActiveForm('login')
                      }}
                      className={styles.link}
                    >
                      <FormattedMessage
                        defaultMessage="Cancel"
                        id="KaP/Yr"
                        description="A link text on forgot password page"
                      />
                    </Box>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </SlideOver>
    </Fragment>
  )
})

const LoginMenu = (): JSX.Element | null => {
  const [showLogin, setShowLogin] = useState(false)

  useEffect(() => {
    setShowLogin(true)
  }, [])

  if (!showLogin) {
    return null
  }

  return <InnerLogin />
}

export default LoginMenu
