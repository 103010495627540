import classNames from 'classnames'
import { isBrowser } from 'react-device-detect'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'

import styles from './HoverLink.module.scss'

interface Props {
  slug?: string
  title: string
  white?: boolean
  onClick?: () => void
  active?: boolean
  type?: 'external' | 'internal'
  forwardedRef?: any
  isButton?: boolean
}

const HoverLink = ({
  slug,
  title,
  white,
  onClick,
  active,
  type,
  forwardedRef,
  isButton,
}: Props) => {
  if (type === 'external') {
    return (
      <div
        className={classNames(styles.hoverLink, {
          [styles.white]: white,
          [styles.active]: active,
          [styles.isBrowser]: isBrowser,
        })}
      >
        <a
          ref={forwardedRef}
          target="_blank"
          href={slug}
          rel="noopener noreferrer"
        >
          {title}
        </a>
      </div>
    )
  }
  return (
    <div
      ref={forwardedRef}
      className={classNames(styles.hoverLink, {
        [styles.white]: white,
        [styles.active]: active,
        [styles.isBrowser]: isBrowser,
      })}
    >
      {isButton ? (
        <button onClick={onClick && onClick}>{title}</button>
      ) : (
        <LinkComponent
          onClick={onClick && onClick}
          href={slug || ''}
          ariaCurrent={active ? 'page' : false}
        >
          {title}
        </LinkComponent>
      )}
    </div>
  )
}

export default HoverLink
