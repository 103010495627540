import { FormattedMessage, useIntl } from 'react-intl'
import { Fragment, useState } from 'react'
import { observer } from 'mobx-react-lite'

import Analytics from 'src/utils/analytics'
import PrimaryButton from 'src/components/UI/PrimaryButton/PrimaryButton'
import messages from 'src/stores/error/messages'
import useStore from 'src/stores/useStore'
import { CartIcon } from 'src/components/UI/Icons/Icons'
import Spinner from 'src/components/UI/Spinner/Spinner'
import { Flex } from 'src/components/UI/Grid/Grid'
import getPriceByAndDiscount from 'src/utils/getPriceByAndDiscount'

import styles from './AddToCart.module.scss'

import type { ProductItem } from 'src/apollo/types'
import type { TrackableProduct } from 'src/utils/analytics'

const AddToCart = ({
  isFavorite,
  item,
  onToggleFavorite,
  product,
  small,
  priceLoaded,
}: {
  isFavorite?: boolean
  item: ProductItem
  onToggleFavorite?: any
  product: any
  small?: boolean
  priceLoaded?: boolean
}): JSX.Element | null => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const { ui, cart, error } = useStore()

  const { stock } = item
  const { variantName } = product

  const addToCart = async () => {
    if (stock && parseInt(stock) > 0) {
      try {
        setLoading(true)
        await cart!.addItem(item)
        setLoading(false)
        // Track product add
        const itemToTrack: TrackableProduct = {
          item: item.item,
          name: product.name,
          price: product.price,
          quantity: 1,
          size: item.name,
          sku: item.sku,
        }

        if (product.categories) {
          itemToTrack.categories = product.categories
        }

        const { priceAsNumber, discountAsNumber } =
          getPriceByAndDiscount(product)
        Analytics.track('add_to_cart', {
          currency: cart?.country?.currency || '',
          product: {
            categories: product.categories,
            name: product.name,
            brand: product.brand,
            variant: item.name,
            price: priceAsNumber,
            discount: discountAsNumber,
            sku: product.sku,
          },
        })

        if (!isFavorite) {
          ui?.setActiveMenu('cart')
        } else {
          if (onToggleFavorite) {
            onToggleFavorite()
          }
        }
      } catch (e: any) {
        setLoading(false)
        error!.warn(messages.addToCart, e)
      }
    }
  }

  return small ? (
    stock && parseInt(stock) > 0 ? (
      <Flex
        className={styles.small}
        width={['40px']}
        height={['40px']}
        alignItems="center"
        justifyContent="center"
        as="button"
        onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          if (addToCart && !cart.pending) {
            addToCart()
          }
        }}
        aria-label={intl.formatMessage({
          defaultMessage: 'Add to cart',
          id: 'bsD1Wr',
          description: 'Label for button that adds a product to cart',
        })}
      >
        {loading ? (
          <Spinner color="#000" width={19} height={19} />
        ) : (
          <CartIcon width={17} height={19} />
        )}
      </Flex>
    ) : null
  ) : (
    <PrimaryButton
      className={styles.addToCartButton}
      onClick={() => {
        if (addToCart && !cart.pending) {
          addToCart()
        }
      }}
      disabled={!stock || parseInt(stock) < 1}
    >
      {loading ? (
        <div>
          <Spinner
            className={styles.spinner}
            color="#fff"
            width={25}
            height={25}
          />
        </div>
      ) : (
        <Fragment>
          {priceLoaded && (!stock || (stock && parseInt(stock) < 1)) ? (
            <div>
              <FormattedMessage
                defaultMessage="Out of stock"
                id="L8WryS"
                description="Button text for 'Add to cart' button when a product is unavailable"
              />
            </div>
          ) : (
            <div>
              <FormattedMessage
                defaultMessage="Shop now"
                id="YvSj7B"
                description="Button text for 'Add to cart' button"
              />
              {variantName &&
                variantName.toLowerCase() !== 'One size'.toLowerCase() &&
                ` – ${variantName}`}
            </div>
          )}
        </Fragment>
      )}
    </PrimaryButton>
  )
}

export default observer(AddToCart)
