/* eslint-disable react/jsx-no-literals */
import axios from 'axios'

import { Box } from 'src/components/UI/Grid/Grid'

import styles from './Preview.module.scss'

const Preview = ({ isPreview }: { isPreview: boolean }) => {
  return isPreview ? (
    <Box
      as="button"
      className={styles.wrapper}
      onClick={() => {
        axios.get('/api/clearPreviewData').then(() => {
          window.location.reload()
        })
      }}
    >
      Turn off preview
    </Box>
  ) : null
}

export default Preview
